import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import FormGenerator from "../../FormGenerator";
import { Helmet } from "react-helmet-async";
import {
  receiptsService,
  paymentTypesService,
  receiptTypesService,
  policiesService,
} from "../../../utils/feathers";

function EditReceipt() {
  const { enqueueSnackbar } = useSnackbar();
  const t = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [receiptsTypes, setReceiptsTypes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await receiptsService.get(id);

      setData(data);

      const paymentTypes = await paymentTypesService.find();

      setPaymentTypes(paymentTypes);

      const receiptsTypes = await receiptTypesService.find();

      setReceiptsTypes(receiptsTypes);

      setIsLoading(false);
    }

    fetchData();
  }, [id]);

  async function save(values, redirect = true) {
    try {
      await receiptsService.patch(id, values);

      if (redirect) {
        history.push("/receipts");
      }
    } catch (e) {
      enqueueSnackbar(t("common.errorSaving"), {
        variant: "error",
      });
    }
  }

  async function validate(values) {
    let errors = {};
    if (!values.ReceiptNumber) {
      errors.ReceiptNumber = t("common:fieldRequired");
    }
    if (!values.PolicyId) {
      errors.PolicyId = t("common:fieldRequired");
    }
    if (!values.NetPremium) {
      errors.NetPremium = t("common:fieldRequired");
    }
    if (!values.Total) {
      errors.Total = t("common:fieldRequired");
    }

    return errors;
  }

  return (
    <div>
      <Helmet>
        <title>Attis - {t("receipts.edit")}</title>
      </Helmet>
      {!isLoading ? (
        <FormGenerator
          autoSave={true}
          fields={[
            {
              label: t("receipts.basicInfoFields"),
              name: "basicInfoFields",
              children: [
                {
                  name: "ReceiptNumber",
                  label: t("receipts.receiptNumber"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "PolicyId",
                  label: t("documents.policy"),
                  required: true,
                  widget: "popup",
                  config: {
                    service: policiesService,
                    label: "PolicyNumber",
                    key: "PolicyId",
                    searchFields: [
                      "policyholder.Name",
                      "policyholder.Surname",
                      "PolicyNumber",
                    ],
                  },
                },
                {
                  name: "ReceiptTypeId",
                  label: t("receipts.type"),
                  widget: "select",
                  options: receiptsTypes.map((value) => {
                    return { value: value.ReceiptTypeId, label: value.TypeName };
                  }),
                },
                {
                  name: "PaymentTypeId",
                  label: t("receipts.paymentType"),
                  widget: "select",
                  options: paymentTypes.map((value) => {
                    return { value: value.PaymentTypeId, label: value.Name };
                  }),
                },
                {
                  name: "EffectiveDate",
                  label: t("receipts.effectiveDate"),
                  widget: "datepicker",
                },
                {
                  name: "ExpirationDate",
                  label: t("receipts.expirationDate"),
                  widget: "datepicker",
                },
                {
                  name: "Comments",
                  label: t("receipts.comments"),
                  widget: "text",
                },
              ],
            },
            {
              label: t("receipts.costFields"),
              name: "costFields",
              children: [
                {
                  name: "NetPremium",
                  label: t("receipts.netPremium"),
                  required: true,
                  widget: "number",
                },
                {
                  name: "Taxes",
                  label: t("receipts.taxes"),
                  widget: "number",
                },
                {
                  name: "TaxesSpecial",
                  label: t("receipts.taxesSpecial"),
                  widget: "number",
                },
                {
                  name: "Consortium",
                  label: t("receipts.consortium"),
                  widget: "number",
                },
                {
                  name: "ExternalSurcharge",
                  label: t("receipts.externalSurcharge"),
                  widget: "number",
                },
                {
                  name: "Total",
                  label: t("receipts.total"),
                  required: true,
                  widget: "number",
                },
              ],
            },
            {
              label: t("receipts.commissionFields"),
              name: "commissionFields",
              children: [
                {
                  name: "Commission",
                  label: t("receipts.commission"),
                  widget: "number",
                },
                {
                  name: "Retention",
                  label: t("receipts.retention"),
                  widget: "number",
                },
              ],
            },
          ]}
          values={data}
          validate={validate}
          onSubmit={save}
        />
      ) : (
        <Skeleton height={10} count={20} />
      )}
    </div>
  );
}

export default EditReceipt;
