import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../../Table";
import { useTranslation } from "react-multi-lang";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { bankAccountsService } from "../../../utils/feathers";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    right: 10,
    bottom: 10,
    left: "auto",
    position: "fixed",
  },
}));

function Accounts() {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({
    skip: 0,
    limit: 50,
    sort: { BankAccountId: -1 },
    query: {},
  });

  const changeFilters = ({ skip, query, sort }) => {
    setFilters((prev) => ({ ...prev, skip, query, sort }));
  };

  const errorMessage = t("common.error");

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await bankAccountsService.find({
          query: {
            $limit: filters.limit,
            $skip: filters.skip,
            $or: filters.query,
            $sort: filters.sort,
          },
        });

        setTotal(data.total);
        setRows(data.data);
      } catch (e) {
        enqueueSnackbar(errorMessage, { variant: "error" });
      }

      setIsLoading(false);
    }

    fetchData();
  }, [filters, errorMessage, enqueueSnackbar]);

  const onDelete = async (id) => {
    await bankAccountsService.remove(id);
  };

  const title = t("accounts.title");

  return (
    <div>
      <Helmet>
        <title>Attis - {title}</title>
      </Helmet>
      <Table
        title={title}
        path="accounts"
        idColumn="BankAccountId"
        tableColumns={[
          { title: t("accounts.id"), name: "BankAccountId" },
          { title: t("accounts.accountName"), name: "BankAccountName" },
          {
            title: t("accounts.policyholderName"),
            name: "policyholder.PolicyholderName",
            getCellValue: (row) =>
              row.policyholder ? row.policyholder.PolicyholderName : undefined,
          },
        ]}
        tableRows={rows}
        totalRows={total}
        changeFilters={changeFilters}
        onDelete={onDelete}
        isLoading={isLoading}
      />
      <Fab
        color="primary"
        aria-label={t("common.new")}
        onClick={() => {
          history.push("/accounts/new");
        }}
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}

export default Accounts;
