import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const PrivateRoute = ({ component: Component, render, ...rest }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: rest.location },
        }}
      />
    );
  }
  return <Route {...rest} component={Component} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

PrivateRoute.defaultProps = {
  component: undefined,
};

export default PrivateRoute;
