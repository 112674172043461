import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import DashboardIcon from "@material-ui/icons/Dashboard";
import UserIcon from "@material-ui/icons/People";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AccountIcon from "@material-ui/icons/AccountBox";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Business";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import MenuIcon from "@material-ui/icons/Menu";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import GroupIcon from "@material-ui/icons/Group";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory, Link, useLocation } from "react-router-dom";
import { version } from "../../package.json";
import { useAuth } from "../context/AuthContext";
import { useTranslation } from "react-multi-lang";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

function ListItemLink(props) {
  const { icon, primary, to } = props;
  const location = useLocation();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        selected={location.pathname === to}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

function Layout({ title, children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const history = useHistory();
  const location = useLocation();

  const t = useTranslation();

  const { logout, auth } = useAuth();

  const userLogout = () => {
    logout();
    history.replace("/login");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getInitials = (text) => {
    return text.split(" ").map((i) => i.charAt(0).toUpperCase());
  };

  const getTitle = () => {
    const path = location.pathname.substring(1);

    let page = null;

    if (path.length > 0) {
      const parts = path.split("/");
      if (parts.length > 1) {
        page = `${parts[0]}.${parts[1]}`;
      } else {
        page = `${parts[0]}.title`;
      }
    } else {
      page = "dashboard.title";
    }

    if (page) {
      return t(`${page}`);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label={t("common.drawerOpen")}
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {getTitle()}
          </Typography>

          <IconButton
            aria-label={t("common.profileMenu")}
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar>{getInitials(auth.name)}</Avatar>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openMenu}
            onClose={handleClose}
          >
            <MenuItem disabled>Attis v{version}</MenuItem>
            <MenuItem onClick={() => history.push("/settings")}>
              {t("common.settings")}
            </MenuItem>

            <MenuItem onClick={userLogout}>
              {t("common.logout", { user: auth.name })}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <List>
          <ListItemLink
            to="/"
            primary={t("common.dashboard")}
            icon={<DashboardIcon />}
          />

          <ListItemLink
            to="/policyholders"
            primary={t("common.policyholders")}
            icon={<UserIcon />}
          />

          <ListItemLink
            to="/policies"
            primary={t("common.policies")}
            icon={<AssignmentIcon />}
          />

          <ListItemLink
            to="/companies"
            primary={t("common.companies")}
            icon={<BusinessIcon />}
          />

          <ListItemLink
            to="/accounts"
            primary={t("common.accounts")}
            icon={<AccountIcon />}
          />

          <ListItemLink
            to="/receipts"
            primary={t("common.receipts")}
            icon={<ReceiptIcon />}
          />

          <ListItemLink
            to="/liquidations"
            primary={t("common.liquidations")}
            icon={<MonetizationOnIcon />}
          />

          <ListItemLink
            to="/documents"
            primary={t("common.documents")}
            icon={<AttachFileIcon />}
          />

          <ListItemLink
            to="/calendar"
            primary={t("common.calendar")}
            icon={<CalendarIcon />}
          />

          <ListItemLink
            to="/leads"
            primary={t("common.leads")}
            icon={<GroupIcon />}
          />
        </List>
      </Drawer>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {children}
        </Container>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Layout;
