import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import FormGenerator from "../../FormGenerator";
import { Helmet } from "react-helmet-async";
import {
  policyholdersService,
  townsService,
  provincesService,
} from "../../../utils/feathers";

function NewPolicyholder() {
  const { enqueueSnackbar } = useSnackbar();
  const t = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [towns, setTowns] = useState([]);
  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const towns = await townsService.find();

      setTowns(towns);

      const provinces = await provincesService.find();
      setProvinces(provinces);

      setIsLoading(false);
    }
    fetchData();
  }, []);

  async function save(values) {
    try {
      await policyholdersService.create(values);

      history.push("/policyholders");
    } catch (e) {
      enqueueSnackbar(t("common.errorSaving"), {
        variant: "error",
      });
    }
  }

  async function validate(values) {
    let errors = {};
    if (!values.Name) {
      errors.Name = t("common.fieldRequired");
    }

    if (!values.Surname) {
      errors.Surname = t("common.fieldRequired");
    }

    return errors;
  }

  return (
    <div>
      <Helmet>
        <title>Attis - {t("policyholders.new")}</title>
      </Helmet>
      {!isLoading ? (
        <FormGenerator
          autoSave={false}
          fields={[
            {
              label: t("policyholders.basicInfoFields"),
              children: [
                {
                  name: "Name",
                  label: t("policyholders.name"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Surname",
                  label: t("policyholders.surname"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Reference",
                  label: t("policyholders.reference"),
                  widget: "text",
                },
                {
                  name: "CivilStatusId",
                  label: t("policyholders.civilStatus"),
                  widget: "select",
                  options: [
                    { value: 1, label: "Soltero" },
                    { value: 2, label: "Casado" },
                    { value: 3, label: "Separado" },
                    { value: 4, label: "Viudo" },
                  ],
                },
                {
                  name: "NIF",
                  label: t("policyholders.nif"),
                  widget: "text",
                },
                {
                  name: "DateOfBirth",
                  label: t("policyholders.dateOfBirth"),
                  widget: "datepicker",
                },
                {
                  name: "DateIDDocument",
                  label: t("policyholders.dateIdDocument"),
                  widget: "datepicker",
                },
                {
                  name: "LanguageId",
                  label: t("policyholders.preferredLanguage"),
                  widget: "select",
                  options: [
                    { value: 1, label: "Spanish" },
                    { value: 2, label: "Catalan" },
                    { value: 3, label: "English" },
                  ],
                },
              ],
            },
            {
              label: t("policyholders.contactInfoFields"),
              children: [
                {
                  name: "Email",
                  label: t("policyholders.email"),
                  widget: "email",
                },
                {
                  name: "Phone",
                  label: t("policyholders.phone"),
                  widget: "text",
                },
              ],
            },
            {
              label: t("policyholders.addressFields"),
              children: [
                {
                  name: "Address",
                  label: t("policyholders.address"),
                  widget: "text",
                },
                {
                  name: "PostalCode",
                  label: t("policyholders.postalCode"),
                  widget: "text",
                },
                {
                  name: "TownId",
                  label: t("policyholders.town"),
                  widget: "select",
                  options: towns.map((value) => {
                    return { value: value.TownId, label: value.TownName };
                  }),
                },
                {
                  name: "ProvinceId",
                  label: t("policyholders.province"),
                  widget: "select",
                  options: provinces.map((value) => {
                    return {
                      value: value.ProvinceId,
                      label: value.ProvinceName,
                    };
                  }),
                },
              ],
            },
          ]}
          values={{}}
          validate={validate}
          onSubmit={save}
        />
      ) : (
        <Skeleton height={10} count={20} />
      )}
    </div>
  );
}

export default NewPolicyholder;
