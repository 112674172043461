import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import FormGenerator from "../../FormGenerator";
import { Helmet } from "react-helmet-async";
import {
  companiesService,
  townsService,
  provincesService,
} from "../../../utils/feathers";

function EditCompany() {
  const { enqueueSnackbar } = useSnackbar();
  const t = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [towns, setTowns] = useState([]);
  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await companiesService.get(id);

      setData(data);

      const towns = await townsService.find();

      setTowns(towns);

      const provinces = await provincesService.find();

      setProvinces(provinces);

      setIsLoading(false);
    }

    fetchData();
  }, [id]);

  async function save(values, redirect = true) {
    try {
      await companiesService.patch(id, values);

      if (redirect) {
        history.push("/companies");
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(t("common.errorSaving"), {
        variant: "error",
      });
    }
  }

  async function validate(values) {
    let errors = {};
    if (!values.CompanyName) {
      errors.CompanyName = t("common.fieldRequired");
    }

    if (!values.CompanyCIF) {
      errors.CompanyCIF = t("common.fieldRequired");
    }

    return errors;
  }

  return (
    <div>
      <Helmet>
        <title>Attis - {t("companies.edit")}</title>
      </Helmet>
      {!isLoading ? (
        <FormGenerator
          autoSave={true}
          fields={[
            {
              label: t("companies.basicInfoFields"),
              children: [
                {
                  name: "CodeDGS",
                  label: t("companies.codeDGS"),
                  widget: "text",
                },
                {
                  name: "CompanyName",
                  label: t("companies.companyName"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "CompanyCIF",
                  label: t("companies.cif"),
                  required: true,
                  widget: "text",
                },
              ],
            },
            {
              label: t("companies.contactInfoFields"),
              children: [
                {
                  name: "ContactName",
                  label: t("companies.name"),
                  widget: "text",
                },
                {
                  name: "ContactPhone",
                  label: t("companies.phone"),
                  widget: "text",
                },
                {
                  name: "ContactEmail",
                  label: t("companies.email"),
                  widget: "email",
                },
              ],
            },
            {
              label: t("companies.addressFields"),
              children: [
                {
                  name: "Address",
                  label: t("companies.address"),
                  widget: "text",
                },
                {
                  name: "PostalCode",
                  label: t("companies.postalCode"),
                  widget: "text",
                },
                {
                  name: "TownId",
                  label: t("companies.town"),
                  widget: "select",
                  options: towns.map((value) => {
                    return { value: value.TownId, label: value.TownName };
                  }),
                },
                {
                  name: "ProvinceId",
                  label: t("companies.province"),
                  widget: "select",
                  options: provinces.map((value) => {
                    return {
                      value: value.ProvinceId,
                      label: value.ProvinceName,
                    };
                  }),
                },
              ],
            },
            {
              label: t("companies.configuationFields"),
              children: [
                {
                  name: "GenerateReceiptsFromFirstPolicy",
                  label: t("companies.generateReceiptAutomatically"),
                  widget: "switch",
                },
                {
                  name: "OverlapReceipts",
                  label: t("companies.overlapReceipts"),
                  widget: "switch",
                },
                {
                  name: "DirectMediator",
                  label: t("companies.directMediator"),
                  widget: "switch",
                },
                {
                  name: "OtherMediator",
                  label: t("companies.otherMediator"),
                  widget: "switch",
                },
                {
                  name: "ImmediateCoverage",
                  label: t("companies.immediateCoverage"),
                  widget: "switch",
                },
              ],
            },
            {
              label: t("companies.accountsFields"),
              children: [
                {
                  name: "AccountCash",
                  label: t("companies.accountCash"),
                  widget: "text",
                },
                {
                  name: "AccountRemittances",
                  label: t("companies.accountRemittances"),
                  widget: "text",
                },
                {
                  name: "AccountReceivePending",
                  label: t("companies.accountReceivePending"),
                  widget: "text",
                },
                {
                  name: "AccountReceivedReturns",
                  label: t("companies.accountReceivedReturns"),
                  widget: "text",
                },
                {
                  name: "AccountRebates",
                  label: t("companies.accountRebates"),
                  widget: "text",
                },
              ],
            },
            {
              label: t("companies.websiteFields"),
              children: [
                {
                  name: "WebsiteURL",
                  label: t("companies.website"),
                  widget: "text",
                },
                {
                  name: "WebsiteUser",
                  label: t("companies.websiteUser"),
                  widget: "text",
                },
                {
                  name: "WebsitePassword",
                  label: t("companies.websitePassword"),
                  widget: "text",
                },
              ],
            },
          ]}
          values={data}
          validate={validate}
          onSubmit={save}
        />
      ) : (
        <Skeleton height={10} count={20} />
      )}
    </div>
  );
}

export default EditCompany;
