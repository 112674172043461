import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import ReactSelect from "react-select";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 15,
  },
  label: {
    marginBottom: 5,
  },
}));

const getSelectTheme = (theme) => {
  const isDark = theme.palette.type === "dark";

  return {
    /*
     * multiValue(remove)/color:hover
     */
    danger: "red",

    /*
     * multiValue(remove)/backgroundColor(focused)
     * multiValue(remove)/backgroundColor:hover
     */
    dangerLight: theme.palette.grey[200],

    /*
     * control/backgroundColor
     * menu/backgroundColor
     * option/color(selected)
     */
    neutral0: theme.palette.background.default,

    /*
     * control/backgroundColor(disabled)
     */
    neutral5: "orange",

    /*
     * control/borderColor(disabled)
     * multiValue/backgroundColor
     * indicators(separator)/backgroundColor(disabled)
     */
    neutral10: "pink",

    /*
     * control/borderColor
     * option/color(disabled)
     * indicators/color
     * indicators(separator)/backgroundColor
     * indicators(loading)/color
     */
    neutral20: theme.palette.grey["A200"],

    /*
     * control/borderColor(focused)
     * control/borderColor:hover
     */
    // this should be the white, that's normally selected
    neutral30: theme.palette.text.primary,

    /*
     * menu(notice)/color
     * singleValue/color(disabled)
     * indicators/color:hover
     */
    neutral40: theme.palette.text.primary,

    /*
     * placeholder/color
     */
    // seen in placeholder text
    neutral50: theme.palette.grey["A200"],

    /*
     * indicators/color(focused)
     * indicators(loading)/color(focused)
     */
    neutral60: isDark ? "white" : theme.palette.text.primary,
    neutral70: isDark ? "white" : theme.palette.text.primary,

    /*
     * input/color
     * multiValue(label)/color
     * singleValue/color
     * indicators/color(focused)
     * indicators/color:hover(focused)
     */
    neutral80: theme.palette.text.primary,

    // no idea
    neutral90: "pink",

    /*
     * control/boxShadow(focused)
     * control/borderColor(focused)
     * control/borderColor:hover(focused)
     * option/backgroundColor(selected)
     * option/backgroundColor:active(selected)
     */
    primary: theme.palette.primary.main,

    /*
     * option/backgroundColor(focused)
     */
    primary25: theme.palette.background.paper,

    /*
     * option/backgroundColor:active
     */
    primary50: theme.palette.background.paper,
    primary75: theme.palette.background.paper,
  };
};

export default function Select({
  meta,
  input,
  label,
  required,
  options,
  customOnChange,
}) {
  const classes = useStyles();

  const theme = useTheme();
  const formThemeColors = getSelectTheme(theme);

  const error = meta.invalid && meta.modified;

  const getValue = () => {
    if (input.value && options.length > 0) {
      const option = options.find((o) => o.value === input.value);
      if (option) {
        return {
          value: input.value,
          label: options.find((o) => o.value === input.value).label,
        };
      }
    }

    return null;
  };

  return (
    <div className={classes.formControl}>
      <InputLabel className={classes.label} shrink error={error}>
        {label} {required && "*"}
      </InputLabel>
      <ReactSelect
        isClearable
        defaultValue={getValue()}
        onChange={(value) => {
          input.onChange(value ? value.value : null);
          if (customOnChange) {
            customOnChange(value);
          }
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...formThemeColors,
          },
        })}
        options={options}
      />
      {error && (
        <InputLabel shrink error={true} style={{ paddingTop: 10 }}>
          {meta.error}
        </InputLabel>
      )}
    </div>
  );
}
