import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Helmet } from "react-helmet-async";
import { useTranslation, setLanguage, getLanguage } from "react-multi-lang";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 20,
  },
}));

function Settings() {
  const t = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const [currentLang, setCurrentLang] = useState(getLanguage());

  const changeLang = (lang) => {
    setCurrentLang(lang);
    localStorage.setItem("lang", lang);
    setLanguage(lang);
  };

  return (
    <div>
      <Helmet>
        <title>Attis - {t("settings.title")}</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="lang">
              {t("settings.changeLanguage")}
            </InputLabel>
            <Select
              value={currentLang}
              onChange={(event) => {
                changeLang(event.target.value);
              }}
              inputProps={{
                name: "lang",
                id: "lang",
              }}
            >
              <MenuItem value={"es"}>Spanish</MenuItem>
              <MenuItem value={"en"}>English</MenuItem>
            </Select>
          </FormControl>
          <br />
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="lang">{t("settings.theme")}</InputLabel>
            <Select
              value={theme.palette.type}
              onChange={(event) => {
                localStorage.setItem("theme", event.target.value);
                window.location.reload();
              }}
              inputProps={{
                name: "theme",
                id: "theme",
              }}
            >
              <MenuItem value={"light"}>{t("settings.light")}</MenuItem>
              <MenuItem value={"dark"}>{t("settings.dark")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

export default Settings;
