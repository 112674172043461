import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import FormGenerator from "../../FormGenerator";
import { Helmet } from "react-helmet-async";
import {
  policiesService,
  categoriesService,
  companiesService,
  paymentTypesService,
  bankAccountsService,
  policyholdersService,
  policiesPolicyholdersService,
  policiesBeneficiariesService,
  documentsService,
  policyLengthsService
} from "../../../utils/feathers";

const sections = [
  "basicInfoFields",
  "policyholders",
  "risk",
  "vehicle",
  "owner",
  "driver",
  "beneficiary",
  "conditions",
];

const categoriesMap = {
  1: ["basicInfoFields", "policyholders", "conditions", "beneficiary"],
  2: ["basicInfoFields", "policyholders", "conditions", "beneficiary"],
  3: ["basicInfoFields", "policyholders", "risk"],
  4: ["basicInfoFields", "vehicle", "owner", "driver"],
  5: ["basicInfoFields", "policyholders", "risk"],
  6: ["basicInfoFields", "policyholders", "risk"],
  7: ["basicInfoFields", "policyholders", "risk"],
  8: ["basicInfoFields", "policyholders"],
  9: ["basicInfoFields", "policyholders", "risk"],
  10: ["basicInfoFields", "policyholders", "risk"],
  11: ["basicInfoFields", "policyholders", "risk"],
  12: ["basicInfoFields", "policyholders"],
  13: ["basicInfoFields", "policyholders", "conditions", "beneficiary"],
  14: ["basicInfoFields", "vehicle", "owner", "driver"],
  15: ["basicInfoFields", "policyholders", "risk"],
  16: ["basicInfoFields", "policyholders", "risk"],
  17: ["basicInfoFields", "policyholders", "conditions", "beneficiary"],
};

function EditPolicy() {
  const { enqueueSnackbar } = useSnackbar();
  const t = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [hidden, setHidden] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [policyLengths, setPolicyLengths] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await policiesService.get(id);

      setData(sanitiser(data));

      const companies = await companiesService.find({ query: { $limit: 50 } });

      setCompanies(companies.data);

      const categories = await categoriesService.find();

      setCategories(categories);

      const paymentTypes = await paymentTypesService.find();

      setPaymentTypes(paymentTypes);

      const policyLengths = await policyLengthsService.find();

      setPolicyLengths(policyLengths);

      setIsLoading(false);
    };

    fetchData();
  }, [id]);

  async function update(values, redirect = false) {
    try {
      await policiesService.patch(id, values);

      await savePolicyholders(values.policies_policyholders);
      await saveBeneficiaries(values.policies_beneficiaries);
      await saveDocuments(values.documents);

      if (redirect) {
        history.push("/policies");
      }
    } catch (e) {
      enqueueSnackbar(t("common.errorSaving"), {
        variant: "error",
      });
    }
  }

  const savePolicyholders = async (values) => {
    const [added, removed] = calculateDiffs(
      data.policies_policyholders,
      values
    );

    if (removed.length > 0) {
      let query = {
        PolicyId: id,
        PolicyHolderId: {
          $in: removed,
        },
      };
      await policiesPolicyholdersService.remove(null, { query });

      setData((prev) => ({
        ...prev,
        policies_policyholders: prev.policies_policyholders.filter(
          (el) => !removed.includes(el)
        ),
      }));
    }

    if (added.length > 0) {
      const query = added.map((item) => ({
        PolicyHolderId: item,
        PolicyId: id,
      }));

      await policiesPolicyholdersService.create(query);

      setData((prev) => ({
        ...prev,
        policies_policyholders: [...prev.policies_policyholders, ...added],
      }));
    }
  };

  const saveBeneficiaries = async (values) => {
    const [added, removed] = calculateDiffs(
      data.policies_beneficiaries,
      values
    );

    if (removed.length > 0) {
      let query = {
        PolicyId: id,
        PolicyHolderId: {
          $in: removed,
        },
      };
      await policiesBeneficiariesService.remove(null, { query });

      setData((prev) => ({
        ...prev,
        policies_beneficiaries: prev.policies_beneficiaries.filter(
          (el) => !removed.includes(el)
        ),
      }));
    }

    if (added.length > 0) {
      const query = added.map((item) => ({
        PolicyHolderId: item,
        PolicyId: id,
      }));

      await policiesBeneficiariesService.create(query);

      setData((prev) => ({
        ...prev,
        policies_beneficiaries: [...prev.policies_beneficiaries, ...added],
      }));
    }
  };

  const saveDocuments = async (values) => {
    const [added, removed] = calculateDiffs(data.documents, values);

    if (removed.length > 0) {
      let query = {
        PolicyId: id,
        DocumentURL: {
          $in: removed,
        },
      };
      await documentsService.remove(null, { query });

      setData((prev) => ({
        ...prev,
        documents: prev.documents.filter((el) => !removed.includes(el)),
      }));
    }

    if (added.length > 0) {
      const query = added.map((item) => ({
        DocumentURL: item,
        PolicyId: id,
      }));

      await documentsService.create(query);

      setData((prev) => ({
        ...prev,
        documents: [...prev.documents, ...added],
      }));
    }
  };

  const calculateDiffs = (arr1, arr2) => {
    const added = arrayDiff(arr1, arr2);
    const removed = arrayDiff(arr2, arr1);
    return [removed, added];
  };

  function arrayDiff(array1, array2) {
    if (Array.isArray(array1) && Array.isArray(array2)) {
      return array1.filter(function (elm) {
        return array2.indexOf(elm) === -1;
      });
    } else {
      return [];
    }
  }

  const sanitiser = (data) => {
    let result = { ...data };
    result.policies_policyholders = result.policies_policyholders.map(
      (item) => item.PolicyHolderId
    );
    result.policies_beneficiaries = result.policies_beneficiaries.map(
      (item) => item.PolicyHolderId
    );

    result.documents = result.documents.map((item) => item.DocumentURL);

    return result;
  };

  async function validate(values) {
    let errors = {};
    if (!values.PolicyHolderId) {
      errors.PolicyHolderId = t("policies.common.fieldRequired");
    }
    if (!values.CompanyId) {
      errors.CompanyId = t("policies.common.fieldRequired");
    }
    if (!values.PolicyNumber) {
      errors.PolicyNumber = t("policies.common.fieldRequired");
    }
    if (!values.BankAccountId) {
      errors.BankAccountId = t("policies.common.fieldRequired");
    }

    return errors;
  }

  function onCategoryChange({ value }) {
    const difference = sections.filter(
      (x) => !categoriesMap[value].includes(x)
    );
    setHidden(difference);
  }

  function onPolicyholderChange({ value }) {}

  useEffect(() => {
    if (data.CategoryId) {
      let difference = sections.filter(
        (x) => !categoriesMap[data.CategoryId].includes(x)
      );
      setHidden(difference);
    }
  }, [data]);

  return (
    <div>
      <Helmet>
        <title>Attis - {t("policies.edit")}</title>
      </Helmet>
      {!isLoading ? (
        <FormGenerator
          hidden={hidden}
          autoSave={true}
          fields={[
            {
              label: t("policies.basicInfoFields"),
              name: "basicInfoFields",
              children: [
                {
                  name: "PolicyHolderId",
                  label: t("policies.policyholder"),
                  required: true,
                  widget: "popup",
                  config: {
                    service: policyholdersService,
                    label: "PolicyholderName",
                    key: "PolicyHolderId",
                    searchFields: ["Name", "Surname"],
                  },

                  onChange: (value) => onPolicyholderChange(value),
                },
                {
                  name: "CompanyId",
                  label: t("policies.company"),
                  required: true,
                  widget: "select",
                  options: companies.map((value) => {
                    return { value: value.CompanyId, label: value.CompanyName };
                  }),
                },
                {
                  name: "CategoryId",
                  label: t("policies.categoryName"),
                  required: true,
                  widget: "select",
                  options: categories.map((value) => {
                    return {
                      value: value.CategoryId,
                      label: value.CategoryName,
                    };
                  }),
                  onChange: (value) => onCategoryChange(value),
                },
                {
                  name: "PolicyNumber",
                  label: t("policies.policyNumber"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "FeeAmount",
                  label: t("policies.feeAmount"),
                  widget: "number",
                },
                {
                  name: "StartDate",
                  label: t("policies.startDate"),
                  widget: "datepicker",
                },
                {
                  name: "EndDate",
                  label: t("policies.endDate"),
                  widget: "datepicker",
                },
                {
                  name: "NewEffectDate",
                  label: t("policies.newEffect"),
                  widget: "datepicker",
                },
                {
                  name: "PaymentTypeId",
                  label: t("policies.paymentType"),
                  widget: "select",
                  options: paymentTypes.map((value) => {
                    return {
                      value: value.PaymentTypeId,
                      label: value.Name,
                    };
                  }),
                },
                {
                  name: "PolicyLengthId",
                  label: t("policies.policyLength"),
                  widget: "select",
                  options: policyLengths.map((value) => {
                    return {
                      value: value.LengthTypeId,
                      label: value.LengthName,
                    };
                  }),
                },

                {
                  name: "BankAccountId",
                  label: t("policies.bankAccount"),
                  required: true,
                  widget: "popup",
                  config: {
                    service: bankAccountsService,
                    label: "BankAccountName",
                    key: "BankAccountId",
                    searchFields: ["BankAccountName"],
                  },
                },

                {
                  name: "Comments",
                  label: t("policies.comments"),
                  widget: "text",
                },
              ],
            },
            {
              label: t("policies.conditions"),
              name: "conditions",
              children: [
                {
                  name: "Description1",
                  label: t("policies.modality"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description2",
                  label: t("policies.capitalGuranteed"),
                  required: true,
                  widget: "text",
                },

                {
                  name: "Description3",
                  label: t("policies.naturalDeath"),
                  required: true,
                  widget: "text",
                },

                {
                  name: "Description4",
                  label: t("policies.deathAccident"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description5",
                  label: t("policies.deathTrafficAccident"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description6",
                  label: t("policies.professionalDisability"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description7",
                  label: t("policies.totalSickness"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description8",
                  label: t("policies.invalidTotalAccident"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description9",
                  label: t("policies.invalidTotalTrafficAccident"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description10",
                  label: t("policies.greatDisability"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description11",
                  label: t("policies.temporaryDisablement"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description12",
                  label: t("policies.franchiseDays"),
                  required: true,
                  widget: "text",
                },

                {
                  name: "Description13",
                  label: t("policies.healthcareExpenses"),
                  required: true,
                  widget: "text",
                },
              ],
            },
            {
              label: t("policies.owner"),
              name: "owner",
              children: [
                {
                  name: "policies_policyholders",
                  label: t("policies.policyNumber"),
                  required: true,
                  widget: "list",
                },
              ],
            },
            {
              label: t("policies.driver"),
              name: "driver",
              children: [
                {
                  name: "policies_beneficiaries",
                  label: t("policies.policyholders"),
                  widget: "list",
                },
              ],
            },
            {
              label: t("policies.risk"),
              name: "risk",
              children: [
                {
                  name: "Description1",
                  label: t("policies.riskDescription"),
                  widget: "text",
                },
                {
                  name: "Description2",
                  label: t("policies.riskLocation"),
                  widget: "text",
                },
                {
                  name: "Description3",
                  label: t("policies.contentsGuarantee"),
                  widget: "text",
                },
                {
                  name: "Description4",
                  label: t("policies.warrantyGuarantee"),
                  widget: "text",
                },
                {
                  name: "Description5",
                  label: t("policies.optionalWarranties"),
                  widget: "text",
                },
                {
                  name: "Description6",
                  label: t("policies.surface"),
                  widget: "text",
                },
              ],
            },
            {
              label: t("policies.vehicle"),
              name: "vehicle",
              children: [
                {
                  name: "Description1",
                  label: t("policies.vehicleRegistration"),
                  widget: "text",
                },
                {
                  name: "Description2",
                  label: t("policies.vehicleType"),
                  widget: "text",
                },
                {
                  name: "Description3",
                  label: t("policies.vehicleMake"),
                  widget: "text",
                },
                {
                  name: "Description4",
                  label: t("policies.vehicleModel"),
                  widget: "text",
                },
                {
                  name: "Description5",
                  label: t("policies.vehiclePlaces"),
                  widget: "text",
                },
                {
                  name: "Description6",
                  label: t("policies.vehicleTare"),
                  widget: "text",
                },
                {
                  name: "Description7",
                  label: t("policies.vehicleLoad"),
                  widget: "text",
                },
                {
                  name: "Description8",
                  label: t("policies.vehicleGarage"),
                  widget: "text",
                },
                {
                  name: "Description9",
                  label: t("policies.vehicleColor"),
                  widget: "text",
                },
                {
                  name: "Description10",
                  label: t("policies.vehicleDedication"),
                  widget: "text",
                },
                {
                  name: "Description13",
                  label: t("policies.vehicleYear"),
                  widget: "text",
                },
                {
                  name: "Description11",
                  label: t("policies.vehicleGuarantees"),
                  widget: "text",
                },
                {
                  name: "Description12",
                  label: t("policies.vehicleTrailer"),
                  widget: "text",
                },
                {
                  name: "VehicleValue",
                  label: t("policies.vehicleValue"),
                  widget: "text",
                },
                {
                  name: "DriverDeath",
                  label: t("policies.driverDeath"),
                  widget: "text",
                },
                {
                  name: "DriverDisability",
                  label: t("policies.driverDisability"),
                  widget: "text",
                },
                {
                  name: "DriverHealthAssistance",
                  label: t("policies.driverHealthAssistance"),
                  widget: "text",
                },
                {
                  name: "VehiclePurchaseDate",
                  label: t("policies.vehiclePurchaseDate"),
                  widget: "datepicker",
                },
              ],
            },
            {
              label: t("policies.policyholders"),
              name: "policyholders",
              children: [
                {
                  name: "policies_policyholders",
                  label: t("policies.policyholders"),
                  widget: "list",
                },
              ],
            },
            {
              label: t("policies.beneficiaries"),
              name: "beneficiary",
              children: [
                {
                  name: "policies_beneficiaries",
                  label: t("policies.beneficiaries"),
                  widget: "list",
                },
              ],
            },
            {
              label: t("policies.receipts"),
              name: "receipts",
              children: [
                {
                  name: "receipts",
                  label: t("policies.receipts"),
                  widget: "listReceipts",
                },
              ],
            },
            {
              label: t("policies.documents"),
              name: "documents",
              children: [
                {
                  name: "documents",
                  label: t("policies.documents"),
                  widget: "fileUploader",
                },
              ],
            },
            {
              label: t("policies.supplements"),
              name: "supplements",
              children: [],
            },
          ]}
          values={data}
          validate={validate}
          onSubmit={update}
        />
      ) : (
        <Skeleton height={10} count={20} />
      )}
    </div>
  );
}

export default EditPolicy;
