import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTranslation, getLanguage } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import { format, parse, startOfWeek, getDay, parseISO } from "date-fns";
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import { Helmet } from "react-helmet-async";
import { calendarService } from "../../../utils/feathers";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".rbc-toolbar button": {
      color: theme.palette.type === "dark" ? "white" : "#373a3c",
    },
    ".rbc-toolbar button:active, .rbc-toolbar button.rbc-active": {
      backgroundColor: theme.palette.type === "dark" ? "#bbb" : "#e6e6e6",
    },
  },
  fab: {
    margin: theme.spacing(1),
    right: 10,
    bottom: 10,
    left: "auto",
    position: "fixed",
  },
}));

const locales = {
  en: enLocale,
  es: esLocale,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function Event({ event }) {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const t = useTranslation();

  const confirmDelete = (id) => {
    confirm({
      title: t("common.confirm"),
      description: t("common.confirmDeleteDesc"),
      confirmationText: t("common.confirm"),
      cancellationText: t("common.cancel"),
    })
      .then(() => {
        handleDelete(id);
      })
      .catch(() => {});
  };

  const handleDelete = async (id) => {
    try {
      await calendarService.remove(id);
      enqueueSnackbar(t("common.itemDeleted"), { variant: "success" });
    } catch (e) {
      enqueueSnackbar(t("common.deleteError"), { variant: "danger" });
    }
  };

  return (
    <span>
      {event.title}
      <IconButton
        style={{ float: "right", color: "white" }}
        size="small"
        onClick={() => confirmDelete(event.id)}
      >
        <DeleteIcon style={{ width: 20, height: 20 }} />
      </IconButton>
    </span>
  );
}

function Events() {
  const [calendar, setCalendar] = useState([]);
  const t = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const locale = getLanguage();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);

  const errorMessage = t("common.error");

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await calendarService.find();

        setCalendar(result.data);
      } catch (e) {
        enqueueSnackbar(errorMessage, { variant: "error" });
      }

      setIsLoading(false);
    }
    fetchData();
  }, [errorMessage, enqueueSnackbar]);

  return (
    <div>
      <Helmet>
        <title>Attis - {t("calendar.title")}</title>
      </Helmet>
      {!isLoading ? (
        <Calendar
          culture={locale}
          localizer={localizer}
          events={calendar.map((event) => {
            return {
              title: event.EventTitle,
              desc: event.EventDescription,
              start: parseISO(event.EventStart),
              end: parseISO(event.EventEnd),
              id: event.EventId,
            };
          })}
          messages={{
            date: t("calendar.date"),
            time: t("calendar.time"),
            event: t("calendar.event"),
            allDay: t("calendar.allDay"),
            week: t("calendar.week"),
            work_week: t("calendar.workWeek"),
            day: t("calendar.day"),
            month: t("calendar.month"),
            previous: t("calendar.previous"),
            next: t("calendar.next"),
            yesterday: t("calendar.yesterday"),
            tomorrow: t("calendar.tomorrow"),
            today: t("calendar.today"),
            agenda: t("calendar.agenda"),
            noEventsInRange: t("calendar.noEventsInRange"),
            showMore: (total) => t("calendar.showMore", { total }),
          }}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onDoubleClickEvent={(event) => {
            history.push(`/calendar/edit/${event.id}`);
          }}
          components={{
            event: (props) => <Event {...props} t={t} />,
          }}
        />
      ) : (
        <Skeleton height={10} count={20} />
      )}
      <Fab
        color="primary"
        aria-label={t("common.new")}
        onClick={() => {
          history.push("/calendar/new");
        }}
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}

export default Events;
