import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "react-final-form";
import Fab from "@material-ui/core/Fab";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-multi-lang";

import AutoSave from "./AutoSave";
import FieldRenderer from "./FieldRenderer";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 20,
  },
  fab: {
    margin: theme.spacing(1),
    top: "auto",
    right: 10,
    bottom: 10,
    left: "auto",
    position: "fixed",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  fabProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      hidden={value !== index}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function FormGenerator({
  fields,
  validate,
  values,
  onSubmit,
  autoSave,
  hidden,
}) {
  const classes = useStyles();

  const t = useTranslation();

  const [saving, setSaving] = useState(false);
  const [value, setValue] = React.useState(0);
  const [formFields, setFormFields] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function saveChanges(values, redirect = false) {
    setSaving(true);
    await onSubmit(values, redirect);
    setSaving(false);
  }

  useEffect(() => {
    let tempFields = fields;

    if (hidden) {
      tempFields = tempFields.filter((field) =>
        hidden.every((item) => item !== field.name)
      );
    }
    setFormFields(tempFields);
  }, [hidden, fields]);

  if (!formFields) {
    return null;
  }

  return (
    <Form
      onSubmit={saveChanges}
      initialValues={values}
      validate={validate}
      render={({ handleSubmit, submitting, invalid, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          {autoSave && <AutoSave debounce={1000} save={saveChanges} />}
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label={t("common.tabs")}
              >
                {formFields.map((field, index) => (
                  <Tab key={index} label={field.label} {...a11yProps(index)} />
                ))}
              </Tabs>
            </AppBar>
            {formFields.map((field, index) => (
              <TabPanel key={index} value={value} index={index}>
                {field.children.map((child, index) => (
                  <FieldRenderer key={index} values={values} {...child} />
                ))}
              </TabPanel>
            ))}
          </div>
          <Fab
            color="primary"
            aria-label={t("common.save")}
            type="submit"
            disabled={saving || invalid || submitting}
            className={classes.fab}
          >
            {saving && (
              <CircularProgress size={68} className={classes.fabProgress} />
            )}
            <SaveIcon />
          </Fab>
        </form>
      )}
    />
  );
}

export default FormGenerator;
