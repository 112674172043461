import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import FormGenerator from "../../FormGenerator";
import { Helmet } from "react-helmet-async";
import {
  policiesService,
  categoriesService,
  companiesService,
  paymentTypesService,
  bankAccountsService,
  policyholdersService,
  policiesPolicyholdersService,
  policiesBeneficiariesService,
  documentsService,
  policyLengthsService,
} from "../../../utils/feathers";

const sections = [
  "basicInfoFields",
  "policyholders",
  "risk",
  "vehicle",
  "owner",
  "driver",
  "beneficiary",
  "conditions",
];

const categoriesMap = {
  1: ["basicInfoFields", "policyholders", "conditions", "beneficiary"],
  2: ["basicInfoFields", "policyholders", "conditions", "beneficiary"],
  3: ["basicInfoFields", "policyholders", "risk"],
  4: ["basicInfoFields", "vehicle", "owner", "driver"],
  5: ["basicInfoFields", "policyholders", "risk"],
  6: ["basicInfoFields", "policyholders", "risk"],
  7: ["basicInfoFields", "policyholders", "risk"],
  8: ["basicInfoFields", "policyholders"],
  9: ["basicInfoFields", "policyholders", "risk"],
  10: ["basicInfoFields", "policyholders", "risk"],
  11: ["basicInfoFields", "policyholders", "risk"],
  12: ["basicInfoFields", "policyholders"],
  13: ["basicInfoFields", "policyholders", "conditions", "beneficiary"],
  14: ["basicInfoFields", "vehicle", "owner", "driver"],
  15: ["basicInfoFields", "policyholders", "risk"],
  16: ["basicInfoFields", "policyholders", "risk"],
  17: ["basicInfoFields", "policyholders", "conditions", "beneficiary"],
};

function NewPolicy() {
  const { enqueueSnackbar } = useSnackbar();
  const t = useTranslation();
  const history = useHistory();

  const [hidden, setHidden] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [policyLengths, setPolicyLengths] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const companies = await companiesService.find({ query: { $limit: 50 } });

      setCompanies(companies.data);

      const categories = await categoriesService.find();

      setCategories(categories);

      const paymentTypes = await paymentTypesService.find();

      setPaymentTypes(paymentTypes);

      const policyLengths = await policyLengthsService.find();

      setPolicyLengths(policyLengths);

      setIsLoading(false);
    }

    fetchData();
  }, []);

  async function save(values) {
    try {
      const result = await policiesService.create(values);

      const id = result.PolicyId;

      if (values.policies_policyholders) {
        await savePolicyholders(values.policies_policyholders, id);
      }

      if (values.policies_beneficiaries) {
        await saveBeneficiaries(values.policies_beneficiaries, id);
      }

      if (values.documents) {
        await saveDocuments(values.documents, id);
      }

      history.push("/policies");
    } catch (e) {
      enqueueSnackbar(t("common.errorSaving"), {
        variant: "error",
      });
    }
  }

  const savePolicyholders = async (values, id) => {
    const query = values.map((item) => ({
      PolicyHolderId: item,
      PolicyId: id,
    }));

    await policiesPolicyholdersService.create(query);
  };

  const saveBeneficiaries = async (values, id) => {
    const query = values.map((item) => ({
      PolicyHolderId: item,
      PolicyId: id,
    }));

    await policiesBeneficiariesService.create(query);
  };

  const saveDocuments = async (values, id) => {
    const query = values.map((item) => ({
      DocumentURL: item,
      PolicyId: id,
    }));

    await documentsService.create(query);
  };

  async function validate(values) {
    let errors = {};
    if (!values.PolicyHolderId) {
      errors.PolicyHolderId = t("common.fieldRequired");
    }
    if (!values.CompanyId) {
      errors.CompanyId = t("common.fieldRequired");
    }
    if (!values.PolicyNumber) {
      errors.PolicyNumber = t("common.fieldRequired");
    }
    if (!values.BankAccountId) {
      errors.BankAccountId = t("common.fieldRequired");
    }

    return errors;
  }

  function onCategoryChange({ value }) {
    const difference = sections.filter(
      (x) => !categoriesMap[value].includes(x)
    );
    setHidden(difference);
  }

  function onPolicyholderChange({ value }) {}

  return (
    <div>
      <Helmet>
        <title>Attis - {t("policies.edit")}</title>
      </Helmet>
      {!isLoading ? (
        <FormGenerator
          hidden={hidden}
          autoSave={false}
          fields={[
            {
              label: t("policies.basicInfoFields"),
              name: "basicInfoFields",
              children: [
                {
                  name: "PolicyHolderId",
                  label: t("policies.policyholder"),
                  required: true,
                  widget: "popup",
                  config: {
                    service: policyholdersService,
                    label: "PolicyholderName",
                    key: "PolicyHolderId",
                    searchFields: ["Name", "Surname"],
                  },

                  onChange: (value) => onPolicyholderChange(value),
                },
                {
                  name: "CompanyId",
                  label: t("policies.company"),
                  required: true,
                  widget: "select",
                  options: companies.map((value) => {
                    return { value: value.CompanyId, label: value.CompanyName };
                  }),
                },
                {
                  name: "CategoryId",
                  label: t("policies.categoryName"),
                  required: true,
                  widget: "select",
                  options: categories.map((value) => {
                    return {
                      value: value.CategoryId,
                      label: value.CategoryName,
                    };
                  }),
                  onChange: (value) => onCategoryChange(value),
                },
                {
                  name: "PolicyNumber",
                  label: t("policies.policyNumber"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "FeeAmount",
                  label: t("policies.feeAmount"),
                  widget: "number",
                },
                {
                  name: "StartDate",
                  label: t("policies.startDate"),
                  widget: "datepicker",
                },
                {
                  name: "EndDate",
                  label: t("policies.endDate"),
                  widget: "datepicker",
                },
                {
                  name: "NewEffectDate",
                  label: t("policies.newEffect"),
                  widget: "datepicker",
                },
                {
                  name: "PaymentTypeId",
                  label: t("policies.paymentType"),
                  widget: "select",
                  options: paymentTypes.map((value) => {
                    return {
                      value: value.PaymentTypeId,
                      label: value.Name,
                    };
                  }),
                },
                {
                  name: "PolicyLengthId",
                  label: t("policies.policyLength"),
                  widget: "select",
                  options: policyLengths.map((value) => {
                    return {
                      value: value.LengthTypeId,
                      label: value.LengthName,
                    };
                  }),
                },

                {
                  name: "BankAccountId",
                  label: t("policies.bankAccount"),
                  required: true,
                  widget: "popup",
                  config: {
                    service: bankAccountsService,
                    label: "BankAccountName",
                    key: "BankAccountId",
                    searchFields: ["BankAccountName"],
                  },
                },

                {
                  name: "Comments",
                  label: t("policies.comments"),
                  widget: "text",
                },
              ],
            },
            {
              label: t("policies.conditions"),
              name: "conditions",
              children: [
                {
                  name: "Description1",
                  label: t("policies.modality"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description2",
                  label: t("policies.capitalGuranteed"),
                  required: true,
                  widget: "text",
                },

                {
                  name: "Description3",
                  label: t("policies.naturalDeath"),
                  required: true,
                  widget: "text",
                },

                {
                  name: "Description4",
                  label: t("policies.deathAccident"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description5",
                  label: t("policies.deathTrafficAccident"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description6",
                  label: t("policies.professionalDisability"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description7",
                  label: t("policies.totalSickness"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description8",
                  label: t("policies.invalidTotalAccident"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description9",
                  label: t("policies.invalidTotalTrafficAccident"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description10",
                  label: t("policies.greatDisability"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description11",
                  label: t("policies.temporaryDisablement"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "Description12",
                  label: t("policies.franchiseDays"),
                  required: true,
                  widget: "text",
                },

                {
                  name: "Description13",
                  label: t("policies.healthcareExpenses"),
                  required: true,
                  widget: "text",
                },
              ],
            },
            {
              label: t("policies.owner"),
              name: "owner",
              children: [
                {
                  name: "policies_policyholders",
                  label: t("policies.policyNumber"),
                  required: true,
                  widget: "list",
                },
              ],
            },
            {
              label: t("policies.driver"),
              name: "driver",
              children: [
                {
                  name: "policies_beneficiaries",
                  label: t("policies.policyholders"),
                  widget: "list",
                },
              ],
            },
            {
              label: t("policies.risk"),
              name: "risk",
              children: [
                {
                  name: "Description1",
                  label: t("policies.riskDescription"),
                  widget: "text",
                },
                {
                  name: "Description2",
                  label: t("policies.riskLocation"),
                  widget: "text",
                },
                {
                  name: "Description3",
                  label: t("policies.contentsGuarantee"),
                  widget: "text",
                },
                {
                  name: "Description4",
                  label: t("policies.warrantyGuarantee"),
                  widget: "text",
                },
                {
                  name: "Description5",
                  label: t("policies.optionalWarranties"),
                  widget: "text",
                },
                {
                  name: "Description6",
                  label: t("policies.surface"),
                  widget: "text",
                },
              ],
            },
            {
              label: t("policies.vehicle"),
              name: "vehicle",
              children: [
                {
                  name: "Description1",
                  label: t("policies.vehicleRegistration"),
                  widget: "text",
                },
                {
                  name: "Description2",
                  label: t("policies.vehicleType"),
                  widget: "text",
                },
                {
                  name: "Description3",
                  label: t("policies.vehicleMake"),
                  widget: "text",
                },
                {
                  name: "Description4",
                  label: t("policies.vehicleModel"),
                  widget: "text",
                },
                {
                  name: "Description5",
                  label: t("policies.vehiclePlaces"),
                  widget: "text",
                },
                {
                  name: "Description6",
                  label: t("policies.vehicleTare"),
                  widget: "text",
                },
                {
                  name: "Description7",
                  label: t("policies.vehicleLoad"),
                  widget: "text",
                },
                {
                  name: "Description8",
                  label: t("policies.vehicleGarage"),
                  widget: "text",
                },
                {
                  name: "Description9",
                  label: t("policies.vehicleColor"),
                  widget: "text",
                },
                {
                  name: "Description10",
                  label: t("policies.vehicleDedication"),
                  widget: "text",
                },
                {
                  name: "Description13",
                  label: t("policies.vehicleYear"),
                  widget: "text",
                },
                {
                  name: "Description11",
                  label: t("policies.vehicleGuarantees"),
                  widget: "text",
                },
                {
                  name: "Description12",
                  label: t("policies.vehicleTrailer"),
                  widget: "text",
                },
                {
                  name: "VehicleValue",
                  label: t("policies.vehicleValue"),
                  widget: "text",
                },
                {
                  name: "DriverDeath",
                  label: t("policies.driverDeath"),
                  widget: "text",
                },
                {
                  name: "DriverDisability",
                  label: t("policies.driverDisability"),
                  widget: "text",
                },
                {
                  name: "DriverHealthAssistance",
                  label: t("policies.driverHealthAssistance"),
                  widget: "text",
                },
                {
                  name: "VehiclePurchaseDate",
                  label: t("policies.vehiclePurchaseDate"),
                  widget: "datepicker",
                },
              ],
            },
            {
              label: t("policies.policyholders"),
              name: "policyholders",
              children: [
                {
                  name: "policies_policyholders",
                  label: t("policies.policyholders"),
                  widget: "list",
                },
              ],
            },
            {
              label: t("policies.beneficiaries"),
              name: "beneficiary",
              children: [
                {
                  name: "policies_beneficiaries",
                  label: t("policies.beneficiaries"),
                  widget: "list",
                },
              ],
            },
            {
              label: t("policies.receipts"),
              name: "receipts",
              children: [
                {
                  name: "receipts",
                  label: t("policies.receipts"),
                  widget: "listReceipts",
                },
              ],
            },
            {
              label: t("policies.documents"),
              name: "documents",
              children: [
                {
                  name: "documents",
                  label: t("policies.documents"),
                  widget: "fileUploader",
                },
              ],
            },
            {
              label: t("policies.supplements"),
              name: "supplements",
              children: [],
            },
          ]}
          values={{}}
          validate={validate}
          onSubmit={save}
        />
      ) : (
        <Skeleton height={10} count={20} />
      )}
    </div>
  );
}

export default NewPolicy;
