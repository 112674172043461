import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import "./App.css";

import { MuiThemeProvider } from "@material-ui/core/styles";

import { DialogProvider } from "muibox";

import { ConfirmProvider } from "material-ui-confirm";

import Login from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import Settings from "./components/pages/Settings";

import Calendar from "./components/pages/Calendar/List";
import CalendarNew from "./components/pages/Calendar/New";
import CalendarEdit from "./components/pages/Calendar/Edit";

import Policyholders from "./components/pages/Policyholders/List";
import PolicyholdersNew from "./components/pages/Policyholders/New";
import PolicyholdersEdit from "./components/pages/Policyholders/Edit";

import Policies from "./components/pages/Policies/List";
import PoliciesNew from "./components/pages/Policies/New";
import PoliciesEdit from "./components/pages/Policies/Edit";

import Companies from "./components/pages/Companies/List";
import CompaniesNew from "./components/pages/Companies/New";
import CompaniesEdit from "./components/pages/Companies/Edit";

import Accounts from "./components/pages/Accounts/List";
import AccountsNew from "./components/pages/Accounts/New";
import AccountsEdit from "./components/pages/Accounts/Edit";

import Leads from "./components/pages/Leads/List";
import LeadsNew from "./components/pages/Leads/New";
import LeadsEdit from "./components/pages/Leads/Edit";

import Documents from "./components/pages/Documents/List";
import DocumentsNew from "./components/pages/Documents/New";
import DocumentsEdit from "./components/pages/Documents/Edit";

import Receipts from "./components/pages/Receipts/List";
import ReceiptsNew from "./components/pages/Receipts/New";
import ReceiptsEdit from "./components/pages/Receipts/Edit";

import Liquidations from "./components/pages/Liquidations";

import PrivateRoute from "./components/PrivateRoute";

import theme from "./theme";

import { AuthProvider } from "./context/AuthContext";

import Layout from "./components/Layout";

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <DialogProvider>
        <ConfirmProvider>
          <AuthProvider>
            <Router>
              <Switch>
                <PrivateRoute exact path="/">
                  <Layout>
                    <Dashboard />
                  </Layout>
                </PrivateRoute>
                <Route exact path="/login" component={Login} />
                <PrivateRoute exact path="/settings">
                  <Layout>
                    <Settings />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/calendar">
                  <Layout>
                    <Calendar />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/calendar/new">
                  <Layout>
                    <CalendarNew />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/calendar/edit/:id">
                  <Layout>
                    <CalendarEdit />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/policyholders">
                  <Layout>
                    <Policyholders />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/policyholders/new">
                  <Layout>
                    <PolicyholdersNew />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/policyholders/edit/:id">
                  <Layout>
                    <PolicyholdersEdit />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/policies">
                  <Layout>
                    <Policies />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/policies/new">
                  <Layout>
                    <PoliciesNew />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/policies/edit/:id">
                  <Layout>
                    <PoliciesEdit />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/companies">
                  <Layout>
                    <Companies />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/companies/new">
                  <Layout>
                    <CompaniesNew />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/companies/edit/:id">
                  <Layout>
                    <CompaniesEdit />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/accounts">
                  <Layout>
                    <Accounts />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/accounts/new">
                  <Layout>
                    <AccountsNew />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/accounts/edit/:id">
                  <Layout>
                    <AccountsEdit />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/leads">
                  <Layout>
                    <Leads />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/leads/new">
                  <Layout>
                    <LeadsNew />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/leads/edit/:id">
                  <Layout>
                    <LeadsEdit />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/documents">
                  <Layout>
                    <Documents />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/documents/new">
                  <Layout>
                    <DocumentsNew />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/documents/edit/:id">
                  <Layout>
                    <DocumentsEdit />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/receipts">
                  <Layout>
                    <Receipts />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/receipts/new">
                  <Layout>
                    <ReceiptsNew />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute exact path="/receipts/edit/:id">
                  <Layout>
                    <ReceiptsEdit />
                  </Layout>
                </PrivateRoute>


                <PrivateRoute exact path="/liquidations">
                  <Layout>
                    <Liquidations />
                  </Layout>
                </PrivateRoute>

                <Redirect to="/" />
              </Switch>
            </Router>
          </AuthProvider>
        </ConfirmProvider>
      </DialogProvider>
    </MuiThemeProvider>
  );
}

export default App;
