import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useTranslation } from "react-multi-lang";
import { receiptsService } from "../../utils/feathers";
import { formatDate } from "../../utils/utils";

function ReceiptList({ policyId }) {
  const t = useTranslation();

  const columns = [
    t("receipts.id"),
    t("receipts.effectiveDate"),
    t("receipts.expirationDate"),
    t("receipts.receiptNumber"),
    t("receipts.total"),
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const results = await receiptsService.find({
        query: {
          $or: { PolicyId: policyId },
        },
      });

      setData(results.data);
    }

    fetchData();
  }, [policyId]);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label={t("receipts.receiptsList")}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.ReceiptId}
                </TableCell>
                <TableCell>{formatDate(row.EffectiveDate)}</TableCell>
                <TableCell>{formatDate(row.ExpirationDate)}</TableCell>
                <TableCell>{row.ReceiptNumber}</TableCell>
                <TableCell>{row.Total}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    href={`/receipts/edit/${row.ReceiptId}`}
                    target="_blank"
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ReceiptList;
