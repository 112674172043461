import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function Popup({ input, label, config, required }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState("");
  const [results, setResults] = useState([]);
  const [searchvalue, setSearchValue] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value) => {
    input.onChange(value);
    handleClose();
  };

  useEffect(() => {
    async function fetchData() {
      const res = await config.service.get(input.value);
      setValue(res[config.label]);
    }

    if (input.value) {
      fetchData();
    }
  }, [input.value, config.service, config.label]);

  const search = async (value) => {
    setSearchValue(value);

    const query = config.searchFields.reduce((acc, val) => {
      const name = val.includes(".") ? `$${val}$` : val;
      acc.push({ [name]: { $like: `%${value}%` } });
      return acc;
    }, []);

    const results = await config.service.find({
      query: {
        $or: query,
      },
    });

    setResults(results.data);
  };

  return (
    <div>
      <TextField
        value={value}
        label={label}
        readOnly
        InputLabelProps={{
          shrink: true,
        }}
        required={required}
        fullWidth
        style={{ marginBottom: 20 }}
        onClick={handleClickOpen}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" onClose={handleClose}>
          {label}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description" component="div" tabIndex={-1}>
            <TextField
              value={searchvalue}
              label={label}
              onChange={(e) => search(e.target.value)}
              readOnly
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{ marginBottom: 20 }}
            />
            <List component="nav" aria-labelledby="nested-list-subheader">
              {results.map((result, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => handleSelect(result[config.key])}
                >
                  <ListItemText primary={result[config.label]} />
                </ListItem>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
