import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Skeleton from "react-loading-skeleton";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Helmet } from "react-helmet-async";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { endOfMonth, startOfMonth } from "date-fns";
import { useTranslation, getLanguage } from "react-multi-lang";
import { useHistory } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import { companiesService, receiptsService } from "../../utils/feathers";
import { formatDate } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    right: 10,
    bottom: 10,
    left: "auto",
    position: "fixed",
  },
}));

const localeMap = {
  en: enLocale,
  es: esLocale,
};
function Liquidations() {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState({
    skip: 0,
    limit: 50,
    sort: { PolicyId: -1 },
    query: {},
  });

  const [companies, setCompanies] = useState([]);
  const [liquidations, setLiquidations] = useState([]);
  const [value, setValue] = useState(null);
  const [from, setFrom] = useState(startOfMonth(new Date()));
  const [to, setTo] = useState(endOfMonth(new Date()));

  const formatLiquidations = (data) => {
    var helper = {};

    return data.reduce(function (r, o) {
      let date = o.PaymentDate.substr(0, 10);

      var key = date + "-" + o.CompanyId;

      if (!helper[key]) {
        helper[key] = {
          CompanyName: o.CompanyName,
          CompanyId: o.CompanyId,
          PaymentDate: o.PaymentDate,
          Total: o.Total,
          Commission: o.Commission,
          LiquidationCompany: o.LiquidationCompany,
          receipts: [],
        };
        r.push(helper[key]);
      } else {
        helper[key].Commission += o.Commission;
        helper[key].Total += o.Total;
        helper[key].LiquidationCompany += o.LiquidationCompany;
      }

      helper[key].receipts.push(o);

      return r;
    }, []);
  };

  const changeTo = (value) => {
    let newQuery = {
      ...filters.query,
      StartDate: {
        $gte: from,
        $lte: value,
      },
    };

    setTo(value);
    setFilters((prev) => ({ ...prev, query: newQuery }));
  };

  const changeFrom = (value) => {
    let newQuery = {
      ...filters.query,
      StartDate: {
        $gte: value,
        $lte: to,
      },
    };

    setFrom(value);
    setFilters((prev) => ({ ...prev, query: newQuery }));
  };

  const errorMessage = t("common.error");

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      try {
        const data = await receiptsService.find({
          query: {
            $limit: 1000,
            "$policy.CompanyId$": value,
            PaymentDate: {
              $gt: from,
              $lt: to,
            },
          },
        });

        setLiquidations(formatLiquidations(data.data));
      } catch (e) {
        enqueueSnackbar(errorMessage, { variant: "error" });
      }

      setIsLoading(false);
    }

    fetchData();
  }, [filters, errorMessage, enqueueSnackbar, from, to, value]);

  useEffect(() => {
    const fetchData = async () => {
      const companies = await companiesService.find({ query: { $limit: 50 } });

      setCompanies(companies.data);
    };

    fetchData();
  }, []);

  const title = t("liquidations.title");

  function loadCompany(value) {
    setValue(value);
  }

  return (
    <div>
      <Helmet>
        <title>Attis - {title}</title>
      </Helmet>
      {!isLoading ? (
        <div>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="company">
                  {t("liquidations.company")}
                </InputLabel>
                <Select
                  value={value}
                  placeholder
                  id="company"
                  label={t("liquidations.company")}
                  onChange={(e) => loadCompany(e.target.value)}
                >
                  {companies.map((company) => (
                    <MenuItem value={company.CompanyId}>
                      {company.CompanyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={localeMap[getLanguage()]}
              >
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  disableToolbar={false}
                  format="dd/MM/yyyy"
                  label={t("liquidations.from")}
                  value={from}
                  onChange={(e) => changeFrom(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={4} xs={12}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={localeMap[getLanguage()]}
              >
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  disableToolbar={false}
                  format="dd/MM/yyyy"
                  label={t("liquidations.to")}
                  value={to}
                  onChange={(e) => changeTo(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <br />

          {liquidations && (
            <TableContainer component={Paper}>
              <Table
                size="small"
                className={classes.table}
                aria-label={t("liquidations.title")}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{t("liquidations.date")}</TableCell>
                    <TableCell>{t("liquidations.total")}</TableCell>
                    <TableCell>{t("liquidations.liquidation")}</TableCell>
                    <TableCell>{t("liquidations.company")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {liquidations.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>{formatDate(row.PaymentDate)}</TableCell>
                      <TableCell>{row.Total.toFixed(2)}</TableCell>
                      <TableCell>{row.Commission.toFixed(2)}</TableCell>
                      <TableCell>{row.LiquidationCompany.toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>{t("liquidations.date")}</TableCell>
                    <TableCell>{t("liquidations.total")}</TableCell>
                    <TableCell>{t("liquidations.liquidation")}</TableCell>
                    <TableCell>{t("liquidations.company")}</TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </div>
      ) : (
        <Skeleton height={10} count={20} />
      )}
      <Fab
        color="primary"
        aria-label={t("common.new")}
        onClick={() => {
          history.push("/liquidations/new");
        }}
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}

export default Liquidations;
