import { createMuiTheme } from "@material-ui/core/styles";

let themeType = "light";
if (typeof window !== "undefined") {
  //we cant use localstorage on the server
  const storedTheme = localStorage.getItem("theme");
  if (storedTheme) {
    themeType = storedTheme;
  }
}

const theme = createMuiTheme({
  palette: {
    type: themeType,
    primary: {
      main: "#5396cd",
    },
    secondary: {
      main: "#448dc9",
    },
  },
});

export default theme;
