import React, {
  useRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import { Template, TemplatePlaceholder } from "@devexpress/dx-react-core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  SortingState,
  PagingState,
  FilteringState,
  DataTypeProvider,
  CustomPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
  Toolbar,
  VirtualTable,
  ExportPanel,
} from "@devexpress/dx-react-grid-material-ui";
import saveAs from "file-saver";
import { useSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import _ from "lodash";

export const Loading = () => (
  <div
    style={{
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      background: "rgba(255, 255, 255, .3)",
      zIndex: 1000,
    }}
  >
    <CircularProgress
      style={{
        position: "absolute",
        fontSize: "20px",
        top: "calc(45% - 10px)",
        left: "calc(50% - 10px)",
      }}
    />
  </div>
);

const PolicyholderCell = ({ value, style, ...restProps }) => {
  const history = useHistory();

  return (
    <Table.Cell>
      {value && (
        <Link
          style={{ cursor: "pointer", fontWeight: 500 }}
          onClick={() =>
            history.push(`policyholders/edit/${restProps.row.PolicyHolderId}`)
          }
        >
          {value}
        </Link>
      )}
    </Table.Cell>
  );
};

const CompanyCell = ({ value, style, ...restProps }) => {
  const history = useHistory();

  return (
    <Table.Cell>
      {value && (
        <Link
          style={{ cursor: "pointer", fontWeight: 500 }}
          onClick={() =>
            history.push(`companies/edit/${restProps.row.CompanyId}`)
          }
        >
          {value}
        </Link>
      )}
    </Table.Cell>
  );
};

const FilterCell = ({ getMessage, onChange, ...props }) => {
  const [currentFilter, setCurrentFilter] = useState("");

  const applyFilter = useRef(_.debounce((q) => onChange(q), 1000)).current;

  const changeFilter = (filter) => {
    setCurrentFilter(filter);
    applyFilter(filter);
  };

  return (
    <TextField
      value={currentFilter}
      placeholder={getMessage("filterPlaceholder")}
      onChange={(e) => changeFilter(e.target.value)}
    />
  );
};

const Cell = (props) => {
  const { column } = props;
  if (column.name.includes("PolicyholderName")) {
    return <PolicyholderCell {...props} />;
  } else if (column.name.includes("CompanyName")) {
    return <CompanyCell {...props} />;
  }

  return <Table.Cell {...props} />;
};

const DateEditor = ({ value, onValueChange, t, ...props }) => {
  const handleChange = (val) => {
    onValueChange(val);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        clearable
        autoOk
        disableToolbar={false}
        format="dd/MM/yyyy"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={t("common.filterPlaceholder")}
        value={value ? value : null}
        onChange={(val) => handleChange(val)}
      />
    </MuiPickersUtilsProvider>
  );
};

const ops = {
  equal: "$eq",
  notEqual: "$ne",
  contains: "$like",
  notContains: "$notLike",
  startsWith: "$like",
  endsWith: "$like",
  greaterThanOrEqual: "$gte",
  lessThanOrEqual: "$lte",
};

function DataTable({
  idColumn,
  path,
  customActions,
  getRowStyle,
  tableRows,
  tableColumns,
  changeFilters,
  totalRows,
  onDelete,
  isLoading,
}) {
  const t = useTranslation();

  const [hasChangedFilters, setHasChangedFilters] = useState(false);
  const [hasChangePage, setHasChangedPage] = useState(false);
  const [hasChangedSort, setHasChangedSort] = useState(false);

  const [rows, setRows] = useState([]);
  const [pageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [sorting, setSorting] = useState([
    { columnName: idColumn, direction: "desc" },
  ]);

  const history = useHistory();
  const exporterRef = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const itemDeleted = t("common.itemDeleted");

  const handleDelete = useCallback(
    async (id) => {
      await onDelete(id);

      setRows((rows) => rows.filter((row) => row[idColumn] !== id));
      enqueueSnackbar(itemDeleted, { variant: "success" });
    },
    [onDelete, enqueueSnackbar, itemDeleted, idColumn]
  );

  const title = t("common.confirm");
  const description = t("common.confirmDeleteDesc");
  const confirmationText = t("common.confirm");
  const cancellationText = t("common.cancel");

  const confirmDelete = useCallback(
    (id) => {
      confirm({
        title,
        description,
        confirmationText,
        cancellationText,
      })
        .then(() => {
          handleDelete(id);
        })
        .catch(() => {});
    },
    [
      handleDelete,
      confirm,
      title,
      description,
      confirmationText,
      cancellationText,
    ]
  );

  const CustomTableRow = useMemo(
    () => ({ row, ...otherProps }) => {
      const rowStyle = getRowStyle ? getRowStyle({ item: row }) : {};
      return <Table.Row {...otherProps} style={rowStyle} />;
    },
    [getRowStyle]
  );

  useEffect(() => {
    setRows(
      tableRows.map((el) => {
        var o = Object.assign({}, el);

        let actions = [];

        if (customActions) {
          customActions.forEach((action, index) => {
            if (action.path && action.action) {
              throw new Error("Cannot have a path and a action");
            }

            let actionPath = null;
            if (action.path) {
              // convert our curly bracket to db fields
              actionPath = action.path.replace(
                /\[\[.*?\]\]/g,
                function (match) {
                  let field = match.slice(2, -2);
                  return o[field];
                }
              );

              actionPath = encodeURI(actionPath);
            }

            actions.push(
              <React.Fragment key={`custom${index}`}>
                <IconButton
                  size="small"
                  color="primary"
                  variant="contained"
                  style={{ marginRight: 5 }}
                  onClick={() =>
                    action.action
                      ? action.action(o)
                      : history.push(`${actionPath}`)
                  }
                >
                  {action.icon}
                </IconButton>
              </React.Fragment>
            );
          });
        }

        actions.push(
          <React.Fragment key="edit">
            <IconButton
              size="small"
              color="primary"
              variant="contained"
              style={{ marginRight: 5 }}
              onClick={() => {
                history.push(`/${path}/edit/${o[idColumn]}`);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              variant="contained"
              onClick={() => confirmDelete(o[idColumn])}
            >
              <DeleteIcon />
            </IconButton>
          </React.Fragment>
        );

        o.actions = actions;

        return o;
      })
    );
  }, [tableRows, confirmDelete, customActions, history, idColumn, path]);

  const onChangeFilter = (newFilters) => {
    setHasChangedFilters(true);

    setFilters(newFilters);
  };

  const onChangePage = (page) => {
    setCurrentPage(page);

    setHasChangedPage(true);
  };

  const onChangeSort = (sort) => {
    const hasChangedSort = (sorting[0].columnName !== sort[0].columnName || sorting[0].direction !== sort[0].direction);

    setSorting(sort);

    setHasChangedSort(hasChangedSort);
  };

  useEffect(() => {
    async function getData() {
      const query = filters.reduce((acc, { columnName, value, operation }) => {
        if (operation === "contains") {
          value = `%${value}%`;
        }

        if (operation === "notContains") {
          value = `%${value}%`;
        }

        if (operation === "endsWith") {
          value = `${value}%`;
        }
        if (operation === "startsWith") {
          value = `%${value}`;
        }

        if (operation === "endsWith") {
          value = `${value}%`;
        }

        const op = ops[operation];

        const name = columnName.includes(".") ? `$${columnName}$` : columnName;
        if (columnName === "policyholder.PolicyholderName") {
          acc.push({ "$policyholder.Name$": { [op]: value } });
          acc.push({ "$policyholder.Surname$": { [op]: value } });
        } else if (columnName === "policy.policyholder.PolicyholderName") {
          acc.push({ "$policy.policyholder.Name$": { [op]: value } });
          acc.push({ "$policy.policyholder.Surname$": { [op]: value } });
        } else {
          acc.push({ [name]: { [op]: value } });
        }

        return acc;
      }, []);

      //we only care about first filter
      const { columnName, direction } = sorting[0];

      if (hasChangedFilters || hasChangedSort || hasChangePage) {
        const name = columnName.includes(".") ? `$${columnName}$` : columnName;

        await changeFilters({
          skip: pageSize * currentPage,
          query,
          sort: {
            [name]: direction === "asc" ? 1 : -1,
          },
        });
      }
    }

    getData();
    // eslint-disable-next-line
  }, [
    filters,
    sorting,
    pageSize,
    currentPage,
    hasChangedFilters,
    hasChangePage,
  ]);

  const [dateFilterOperations] = useState([
    "equal",
    "notEqual",
    "greaterThanOrEqual",
    "lessThanOrEqual",
  ]);

  const columns = [
    ...tableColumns,
    { title: t("common.actions"), name: "actions" },
  ];
  let dateColumns = [];
  let sortingColumns = [];
  columns.forEach((el) => {
    if (el.name.includes("Date")) {
      dateColumns.push(el.name);
    }
    sortingColumns.push({
      columnName: el.name,
      sortingEnabled: el.name.includes(".") ? false : true,
    });
  });

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "export.xlsx"
      );
    });
  };

  return (
    <Paper>
      <Grid rows={rows} columns={columns}>
        <DataTypeProvider
          for={dateColumns}
          availableFilterOperations={dateFilterOperations}
          editorComponent={(props) => <DateEditor t={t} {...props} />}
        />
        <FilteringState
          filters={filters}
          onFiltersChange={onChangeFilter}
          columnExtensions={[
            { columnName: "actions", filteringEnabled: false },
          ]}
        />

        <SortingState
          sorting={sorting}
          onSortingChange={onChangeSort}
          columnExtensions={[
            { columnName: "actions", sortingEnabled: false },
            ...sortingColumns,
          ]}
        />

        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={onChangePage}
          pageSize={pageSize}
        />
        <CustomPaging totalCount={totalRows} />

        <VirtualTable cellComponent={Cell} rowComponent={CustomTableRow} />

        <TableHeaderRow showSortingControls={true} />

        <TableFilterRow
          showFilterSelector
          editorComponent={FilterCell}
          messages={{
            filterPlaceholder: t("common.filterPlaceholder"),
            contains: t("common.contains"),
            notContains: t("common.notContains"),
            startsWith: t("common.startsWith"),
            endsWith: t("common.endsWith"),
            equal: t("common.equal"),
            notEqual: t("common.notEqual"),
            greaterThanOrEqual: t("common.greaterThanOrEqual"),
            lessThanOrEqual: t("common.lessThanOrEqual"),
          }}
        />

        <PagingPanel />
        <Toolbar />

        <ExportPanel
          startExport={startExport}
          messages={{ exportAll: t("common.exportAll") }}
        />

        <Template name="toolbarContent">
          <TemplatePlaceholder />

          <IconButton variant="contained" onClick={() => onChangeFilter([])}>
            <ClearIcon />
          </IconButton>
        </Template>
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={tableRows}
        columns={columns}
        onSave={onSave}
      />

      {isLoading && <Loading />}
    </Paper>
  );
}

DataTable.propTypes = {
  tableColumns: PropTypes.array.isRequired,
  idColumn: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  customActions: PropTypes.array,
  getRowStyle: PropTypes.func,
};

DataTable.defaultProps = {
  getRowStyle: undefined,
};

export default DataTable;
