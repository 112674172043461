import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EventsIcon from "@material-ui/icons/Event";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from "@material-ui/icons/Warning";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Skeleton from "react-loading-skeleton";
import { useSnackbar } from "notistack";
import { formatDate } from "../../utils/utils";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-multi-lang";
import { addDays } from "date-fns";

import {
  receiptsService,
  policiesService,
  calendarService,
} from "../../utils/feathers";

function ListItemLink(props) {
  const { icon, primary, to, secondary } = props;
  const location = useLocation();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        selected={location.pathname === to}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    </li>
  );
}
function Dashboard() {
  const t = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [recentPolicies, setRecentPolicies] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [expiringSoon, setExpiringSoon] = useState([]);
  const [recentReceipts, setRecentReceipts] = useState([]);

  const errorMessage = t("common.error");

  useEffect(() => {
    async function fetchData() {
      try {
        const policies = await policiesService.find({
          query: {
            $limit: 5,
            $sort: { PolicyId: -1 },
          },
        });

        setRecentPolicies(policies.data);

        const expiring = await policiesService.find({
          query: {
            $limit: 5,
            $sort: { PolicyId: -1 },
            EndDate: {
              $gt: new Date(),
              $lt: addDays(new Date(), 7),
            },
          },
        });

        setExpiringSoon(expiring.data);

        const receipts = await receiptsService.find({
          query: {
            $limit: 5,
            $sort: { ReceiptId: -1 },
          },
        });

        setRecentReceipts(receipts.data);

        const events = await calendarService.find({
          query: {
            EventStart: {
              $gt: new Date(),
              $lt: addDays(new Date(), 7),
            },
          },
        });

        setUpcomingEvents(events.data);
      } catch (e) {
        enqueueSnackbar(errorMessage, { variant: "error" });
      }

      setIsLoading(false);
    }

    fetchData();
  }, [enqueueSnackbar, errorMessage]);

  return (
    <div>
      <Helmet>
        <title>Attis - {t("dashboard.title")}</title>
      </Helmet>
      {!isLoading ? (
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <Typography variant="h5">
              {t("dashboard.recentPolicies")}
            </Typography>
            <List component="nav" aria-label={t("dashboard.recentPolicies")}>
              {recentPolicies.length ? (
                recentPolicies.map((policy, index) => (
                  <ListItemLink
                    key={index}
                    to={`/policies/edit/${policy.PolicyId}`}
                    primary={`${policy.policyholder.Name} ${policy.policyholder.Surname}`}
                    secondary={`${policy.PolicyNumber} (${policy.company.CompanyName})`}
                    icon={<AssignmentIcon />}
                  />
                ))
              ) : (
                <p>{t("dashboard.noData")}</p>
              )}
            </List>
          </Grid>
          <Grid item md={6} sm={12}>
            <Typography variant="h5">
              {t("dashboard.policiesExpiringSoon")}
            </Typography>
            <List
              component="nav"
              aria-label={t("dashboard.policiesExpiringSoon")}
            >
              {expiringSoon.length ? (
                expiringSoon.map((policy, index) => (
                  <ListItemLink
                    key={index}
                    to={`/policies/edit/${policy.PolicyId}`}
                    primary={`${policy.policyholder.Name} ${policy.policyholder.Surname}`}
                    secondary={`${policy.PolicyNumber} (${policy.company.CompanyName})`}
                    icon={<WarningIcon />}
                  />
                ))
              ) : (
                <p>{t("dashboard.noData")}</p>
              )}
            </List>
          </Grid>
          <Grid item md={6} sm={12}>
            <Typography variant="h5">
              {t("dashboard.upcomingEvents")}
            </Typography>
            <List component="nav" aria-label={t("dashboard.upcomingEvents")}>
              {upcomingEvents.length ? (
                upcomingEvents.map((event, index) => (
                  <ListItemLink
                    key={index}
                    to={`/calendar/edit/${event.EventId}`}
                    primary={`${formatDate(event.EventStart)} - ${
                      event.EventTitle
                    }`}
                    secondary={`${
                      event.EventDescription
                        ? event.EventDescription
                        : t("dashboard.noData")
                    }`}
                    icon={<EventsIcon />}
                  />
                ))
              ) : (
                <p>{t("dashboard.noData")}</p>
              )}
            </List>
          </Grid>
          <Grid item md={6} sm={12}>
            <Typography variant="h5">
              {t("dashboard.recentReceipts")}
            </Typography>
            <List component="nav" aria-label={t("dashboard.recentReceipts")}>
              {recentReceipts.length ? (
                recentReceipts.map((receipt, index) => (
                  <ListItemLink
                    key={index}
                    to={`/receipts/edit/${receipt.ReceiptId}`}
                    primary={`€${receipt.Total} - ${receipt.policy.policyholder.Name} ${receipt.policy.policyholder.Surname}`}
                    secondary={`${receipt.policy.PolicyNumber} (${receipt.policy.company.CompanyName})`}
                    icon={<ReceiptIcon />}
                  />
                ))
              ) : (
                <p>{t("dashboard.noData")}</p>
              )}
            </List>
          </Grid>
        </Grid>
      ) : (
        <Skeleton height={10} count={20} />
      )}
    </div>
  );
}

export default Dashboard;
