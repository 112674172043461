import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-multi-lang";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { version } from "../../../package.json";
import { useAuth } from "../../context/AuthContext";

import attisLogo from "../../assets/attisLogo.png";
import attisBackground from "../../assets/attisBackground.jpg";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://attis.es/">
        Attis v{version}
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${attisBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: theme.spacing(1),
    height: 150,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login() {
  const classes = useStyles();
  const t = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState({ username: "", password: "" });
  const { isAuthenticated, login } = useAuth();

  if (isAuthenticated) {
    history.push("/");
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await login(user);
    } catch (error) {
      enqueueSnackbar(t("login.error"), {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  const handleChange = (event) => {
    setUser((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div>
      <Helmet>
        <title>{t("login.title")}</title>
      </Helmet>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={false} md={7} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <img className={classes.logo} src={attisLogo} alt="Attis" />

            <form
              className={classes.form}
              noValidate
              autoComplete="off"
              onSubmit={(event) => handleFormSubmit(event)}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label={t("login.username")}
                name="username"
                autoComplete="username"
                value={user.username}
                onChange={handleChange}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("login.password")}
                type="password"
                id="password"
                value={user.password}
                onChange={handleChange}
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {t("login.login")}
              </Button>

              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
