import React from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import FormGenerator from "../../../components/FormGenerator";
import { addHours } from "date-fns";
import { Helmet } from "react-helmet-async";
import { calendarService, policiesService } from "../../../utils/feathers";

function NewEvent() {
  const history = useHistory();
  const t = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  async function save(values) {
    try {
      await calendarService.create(values);

      history.push("/calendar");
    } catch (e) {
      enqueueSnackbar(t("common.errorSaving"), { variant: "error" });
    }
  }

  async function validate(values) {
    let errors = {};
    if (!values.EventStart) {
      errors.EventStart = t("common.fieldRequired");
    }

    if (!values.EventEnd) {
      errors.EventEnd = t("common.fieldRequired");
    }

    if (!values.EventTitle) {
      errors.EventTitle = t("common.fieldRequired");
    }

    return errors;
  }

  return (
    <div>
      <Helmet>
        <title>Attis - {t("calendar.new")}</title>
      </Helmet>
      <FormGenerator
        autoSave={false}
        fields={[
          {
            label: t("calendar.basicInfoFields"),
            children: [
              {
                name: "PolicyId",
                label: t("calendar.policy"),
                required: true,
                widget: "popup",
                config: {
                  service: policiesService,
                  label: "PolicyNumber",
                  key: "PolicyId",
                  searchFields: [
                    "policyholder.Name",
                    "policyholder.Surname",
                    "PolicyNumber",
                  ],
                },
              },
              {
                name: "EventStart",
                label: t("calendar.eventStart"),
                required: true,
                widget: "datetimepicker",
              },
              {
                name: "EventEnd",
                label: t("calendar.eventEnd"),
                required: true,
                widget: "datetimepicker",
              },
              {
                name: "EventTitle",
                label: t("calendar.eventTitle"),
                required: true,
                widget: "text",
              },
              {
                name: "EventDescription",
                label: t("calendar.eventDescription"),
                widget: "text",
              },
            ],
          },
        ]}
        values={{ EventStart: new Date(), EventEnd: addHours(new Date(), 1) }}
        validate={validate}
        onSubmit={save}
      />
    </div>
  );
}

export default NewEvent;
