import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { SnackbarProvider } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { HelmetProvider } from "react-helmet-async";

import { setDefaultTranslations, setDefaultLanguage } from "react-multi-lang";
import es from "./translations/es.json";
import en from "./translations/en.json";

import reportWebVitals from "./reportWebVitals";

setDefaultTranslations({ es, en });
setDefaultLanguage("es");

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <SnackbarProvider
    ref={notistackRef}
    action={(key) => (
      <IconButton style={{ color: "white" }} onClick={onClickDismiss(key)}>
        <CloseIcon />
      </IconButton>
    )}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
  >
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </SnackbarProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
