import React from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import FormGenerator from "../../FormGenerator";
import { Helmet } from "react-helmet-async";
import { leadsService } from "../../../utils/feathers";

function NewLead() {
  const { enqueueSnackbar } = useSnackbar();
  const t = useTranslation();
  const history = useHistory();

  async function save(values) {
    try {
      await leadsService.create(values);

      history.push("/leads");
    } catch (e) {
      enqueueSnackbar(t("common.errorSaving"), {
        variant: "error",
      });
    }
  }

  async function validate(values) {
    let errors = {};
    if (!values.LeadName) {
      errors.Name = t("common.fieldRequired");
    }

    if (!values.LeadSurname) {
      errors.Surname = t("common.fieldRequired");
    }

    return errors;
  }

  return (
    <div>
      <Helmet>
        <title>Attis - {t("leads.new")}</title>
      </Helmet>
      <FormGenerator
        autoSave={false}
        fields={[
          {
            label: t("leads.basicInfoFields"),
            children: [
              {
                name: "LeadName",
                label: t("leads.name"),
                required: true,
                widget: "text",
              },
              {
                name: "LeadSurname",
                label: t("leads.surname"),
                required: true,
                widget: "text",
              },
              {
                name: "LeadEmail",
                label: t("leads.email"),
                widget: "text",
              },
              {
                name: "LeadPhone",
                label: t("leads.phone"),
                widget: "text",
              },
              {
                name: "LastContactedDate",
                label: t("leads.lastContactedDate"),
                widget: "datepicker",
              },
            ],
          },
        ]}
        values={{}}
        validate={validate}
        onSubmit={save}
      />
    </div>
  );
}

export default NewLead;
