import React from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import FormGenerator from "../../FormGenerator";
import { Helmet } from "react-helmet-async";
import { documentsService, policiesService } from "../../../utils/feathers";

function NewDocument() {
  const { enqueueSnackbar } = useSnackbar();
  const t = useTranslation();
  const history = useHistory();

  async function save(values) {
    try {
      await documentsService.create(values);

      history.push("/documents");
    } catch (e) {
      enqueueSnackbar(t("common.errorSaving"), {
        variant: "error",
      });
    }
  }

  async function validate(values) {
    let errors = {};
    if (!values.PolicyId) {
      errors.PolicyId = t("common.fieldRequired");
    }
    if (!values.DocumentName) {
      errors.DocumentName = t("common.fieldRequired");
    }
    if (!values.DocumentURL) {
      errors.DocumentURL = t("common:fieldRequired");
    }
    return errors;
  }

  return (
    <div>
      <Helmet>
        <title>Attis - {t("documents.new")}</title>
      </Helmet>
      <FormGenerator
        autoSave={false}
        fields={[
          {
            label: t("documents.basicInfoFields"),
            name: "basicInfoFields",
            children: [
              {
                name: "PolicyId",
                label: t("documents.policy"),
                required: true,
                widget: "popup",
                config: {
                  service: policiesService,
                  label: "PolicyNumber",
                  key: "PolicyId",
                  searchFields: [
                    "policyholder.Name",
                    "policyholder.Surname",
                    "PolicyNumber",
                  ],
                },
              },
              {
                name: "DocumentName",
                label: t("documents.documentName"),
                required: true,
                widget: "text",
              },
              {
                name: "DocumentURL",
                label: t("documents.documentURL"),
                required: true,
                widget: "fileUploaderSingle",
              },
            ],
          },
        ]}
        values={{}}
        validate={validate}
        onSubmit={save}
      />
    </div>
  );
}

export default NewDocument;
