import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import ViewIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import Uppy from "@uppy/core";
import { Dashboard, useUppy } from "@uppy/react";
import AwsS3 from "@uppy/aws-s3";
import ImageCompressor from "uppy-plugin-image-compressor";
import English from "@uppy/locales/lib/en_US";
import Spanish from "@uppy/locales/lib/es_ES";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import { saveAs } from "file-saver";
import { getLanguage } from "react-multi-lang";

import { uploadService } from "../../utils/feathers";

import documentIcon from "../../assets/documentIcon.png";

const localeMap = {
  en: English,
  es: Spanish,
};

const useStyles = makeStyles((theme) => ({
  label: {
    marginTop: 5,
    marginBottom: 5,
  },
  controls: {},
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  file: { height: 100, width: 100 },
}));

function Uploader({ meta, input, label, required, ...props }) {
  const classes = useStyles();

  const theme = useTheme();

  const locale = getLanguage();
  const error = meta.invalid && meta.modified;

  const uppy = useUppy(() => {
    return new Uppy({ locale: localeMap[locale] })
      .use(ImageCompressor, {
        maxWidth: 1000,
        quality: 0.6,
      })
      .use(AwsS3, {
        async getUploadParameters(file) {
          const result = await uploadService.create({
            destination: "documents",
            filename: file.name,
          });

          return {
            url: result.url,
            method: result.method,
          };
        },
      })
      .on("upload-success", (file, resp) => {
        const url = resp.uploadURL;
        input.onChange(url);
      });
  }, [input]);

  function handleDownload(file, name) {
    saveAs(file, name);
  }

  return (
    <div>
      <div className={classes.wrapper}>
        {input.value && (
          <div>
            <img
              className={classes.file}
              alt={input.value.split("/").pop().substring(12)}
              src={documentIcon}
            />

            <div className={classes.controls}>
              <IconButton
                onClick={() =>
                  handleDownload(
                    input.value,
                    input.value.split("/").pop().substring(12)
                  )
                }
              >
                <ViewIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
      <InputLabel className={classes.label} shrink error={error}>
        {label} {required && "*"}
      </InputLabel>
      <Dashboard uppy={uppy} {...props} theme={theme.palette.type} />
    </div>
  );
}

export default Uploader;
