import React, { useContext, useState, useEffect } from "react";
import { LinearProgress } from "@material-ui/core";

import { app } from "../utils/feathers";

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const logout = () => {
    app.logout();

    setIsAuthenticated(false);
    setAuth(null);
  };

  const login = async (user) => {
    try {
      const result = await app.authenticate({
        login: user.username,
        password: user.password,
        strategy: "local",
      });

      setAuth(result.user);
      setIsAuthenticated(true);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    async function checkLogin() {
      try {
        const result = await app.authenticate();
        setAuth(result.user);
        setIsAuthenticated(true);
      } catch (e) {
        //login error
      }

      setIsLoading(false);
    }

    checkLogin();
  }, []);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        logout,
        login,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const auth = useContext(AuthContext);
  if (auth == null) {
    throw new Error("You must call useAuth only when inside a AuthProvider.");
  }
  return auth;
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthContext, AuthConsumer };
