import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import FormGenerator from "../../FormGenerator";
import { Helmet } from "react-helmet-async";
import { leadsService } from "../../../utils/feathers";

function EditLead() {
  const { enqueueSnackbar } = useSnackbar();
  const t = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchData() {
      const data = await leadsService.get(id);

      setData(data);

      setIsLoading(false);
    }

    fetchData();
  }, [id]);

  async function save(values, redirect = true) {
    try {
      await leadsService.patch(id, values);

      if (redirect) {
        history.push("/leads");
      }
    } catch (e) {
      enqueueSnackbar(t("common.errorSaving"), {
        variant: "error",
      });
    }
  }

  async function validate(values) {
    let errors = {};
    if (!values.LeadName) {
      errors.Name = t("common.fieldRequired");
    }

    if (!values.LeadSurname) {
      errors.Surname = t("common.fieldRequired");
    }

    return errors;
  }

  return (
    <div>
      <Helmet>
        <title>Attis - {t("leads.new")}</title>
      </Helmet>
      {!isLoading ? (
        <FormGenerator
          autoSave={false}
          fields={[
            {
              label: t("leads.basicInfoFields"),
              children: [
                {
                  name: "LeadName",
                  label: t("leads.name"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "LeadSurname",
                  label: t("leads.surname"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "LeadEmail",
                  label: t("leads.email"),
                  widget: "text",
                },
                {
                  name: "LeadPhone",
                  label: t("leads.phone"),
                  widget: "text",
                },
                {
                  name: "LastContactedDate",
                  label: t("leads.lastContactedDate"),
                  widget: "datepicker",
                },
              ],
            },
          ]}
          values={data}
          validate={validate}
          onSubmit={save}
        />
      ) : (
        <Skeleton height={10} count={20} />
      )}
    </div>
  );
}

export default EditLead;
