import React, { useCallback, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import styled from "styled-components";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { parseISO, isPast } from "date-fns";
import queryString from "query-string";
import { es } from "date-fns/locale";
import { useTranslation } from "react-multi-lang";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import _ from "lodash";
import { useSnackbar } from "notistack";
import Table from "../../Table";
import { policiesService } from "../../../utils/feathers";
import { formatDate } from "../../../utils/utils";

const Filters = styled(Grid)`
  margin-bottom: 25px;
`;

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    right: 10,
    bottom: 10,
    left: "auto",
    position: "fixed",
  },
}));

const getMonths = () => {
  const months = [];

  let i = 0;
  for (i = 0; i < 12; i++) {
    months.push({
      value: i + 1,
      label: es.localize.month(i),
    });
  }
  return months;
};

function Policies() {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [month, setMonth] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const getQuery = useCallback(() => {
    if (search) {
      let val = queryString.parse(search);
      if (!_.isEmpty(val)) {
        return JSON.parse(val.filter);
      }
    }

    return {};
  }, [search]);

  const [filters, setFilters] = useState({
    skip: 0,
    limit: 50,
    sort: { PolicyId: -1 },
    query: getQuery(),
    and: {},
  });

  const changeMonth = (value) => {
    let and = {};
    if (value) {
      and = {
        $month: value,
      };
    }

    setMonth(value);
    setFilters((prev) => ({ ...prev, and }));
  };

  const changeFilters = async ({ skip, query, sort }) => {
    setFilters((prev) => ({ ...prev, skip, query, sort }));
  };

  const errorMessage = t("common.error");

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      try {
        const data = await policiesService.find({
          query: {
            $limit: filters.limit,
            $skip: filters.skip,
            $or: filters.query,
            $sort: filters.sort,
            $and: filters.and,
          },
        });

        setTotal(data.total);
        setRows(data.data);
      } catch (e) {
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
      setIsLoading(false);
    }

    fetchData();
  }, [filters, enqueueSnackbar, errorMessage]);

  const onDelete = async (id) => {
    await policiesService.remove(id);
  };

  const title = t("policies.title");

  const monthList = getMonths();

  return (
    <div>
      <Helmet>
        <title>Attis - {title}</title>
      </Helmet>
      <Filters container spacing={2}>
        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>{t("policies.month")}</InputLabel>
            <Select
              displayEmpty
              value={month}
              id="month"
              label={t("policies.month")}
              onChange={(e) => changeMonth(e.target.value)}
            >
              <MenuItem value="">{t("policies.selectMonth")}</MenuItem>
              {monthList.map((month) => (
                <MenuItem key={month} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Filters>

      <Table
        title={title}
        getRowStyle={({ item }) => {
          const isPastDate = isPast(parseISO(item.EndDate));
          return {
            backgroundColor: isPastDate ? "#F8D7DA" : null,
          };
        }}
        path="policies"
        idColumn="PolicyId"
        tableColumns={[
          { title: t("policies.id"), name: "PolicyId" },
          { title: t("policies.number"), name: "PolicyNumber" },
          {
            title: t("policies.policyholderName"),
            name: "policyholder.PolicyholderName",
            getCellValue: (row) =>
              row.policyholder ? row.policyholder.PolicyholderName : undefined,
          },
          {
            title: t("policies.companyName"),
            name: "company.CompanyName",
            getCellValue: (row) =>
              row.company ? row.company.CompanyName : undefined,
          },
          {
            title: t("policies.categoryName"),
            name: "category.CategoryName",
            getCellValue: (row) =>
              row.category ? row.category.CategoryName : undefined,
          },
          {
            title: t("policies.startDate"),
            name: "StartDate",
            getCellValue: (row) =>
              row.StartDate ? formatDate(row.StartDate) : undefined,
          },
          {
            title: t("policies.endDate"),
            name: "EndDate",
            getCellValue: (row) =>
              row.EndDate ? formatDate(row.EndDate) : undefined,
          },
        ]}
        tableRows={rows}
        totalRows={total}
        changeFilters={changeFilters}
        onDelete={onDelete}
        isLoading={isLoading}
      />
      <Fab
        color="primary"
        aria-label={t("common.new")}
        onClick={() => {
          history.push("/policies/new");
        }}
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}

export default Policies;
