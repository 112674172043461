import feathers from "@feathersjs/feathers";
import rest from "@feathersjs/rest-client";
import auth from "@feathersjs/authentication-client";

const app = feathers();

// Connect to a different URL
const restClient = rest(process.env.REACT_APP_API_URL);

// Configure an AJAX library (see below) with that client
app.configure(restClient.fetch(window.fetch));
app.configure(
  auth({
    storageKey: "auth",
  })
);

const calendarService = app.service("events");
const policiesService = app.service("policies");
const policyholdersService = app.service("policyholders");
const townsService = app.service("towns");
const provincesService = app.service("provinces");
const bankAccountsService = app.service("bank-accounts");
const companiesService = app.service("companies");
const leadsService = app.service("leads");
const documentsService = app.service("documents");
const receiptsService = app.service("receipts");
const receiptTypesService = app.service("receipt-types");
const paymentTypesService = app.service("payment-types");
const categoriesService = app.service("categories");
const uploadService = app.service("upload");
const policiesPolicyholdersService = app.service("policies-policyholders");
const policiesBeneficiariesService = app.service("policies-beneficiaries");
const policyLengthsService = app.service("policy-length-types");

export {
  app,
  calendarService,
  policiesService,
  policyholdersService,
  provincesService,
  bankAccountsService,
  townsService,
  companiesService,
  leadsService,
  documentsService,
  receiptsService,
  receiptTypesService,
  paymentTypesService,
  categoriesService,
  uploadService,
  policiesPolicyholdersService,
  policiesBeneficiariesService,
  policyLengthsService,
};
