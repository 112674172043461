import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Switches,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "mui-rff";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";

import { Field } from "react-final-form";
import Popup from "./fields/Popup";
import Select from "./fields/Select";
import Uploader from "./fields/Uploader";
import UploaderSingle from "./fields/UploaderSingle";
import List from "./fields/List";
import ReceiptsList from "./fields/ReceiptsList";
import { getLanguage } from "react-multi-lang";

const WIDGET = {
  TEXT: "text",
  NUMBER: "number",
  EMAIL: "email",
  SWITCH: "switch",
  SELECT: "select",
  POPUP: "popup",
  DATEPICKER: "datepicker",
  DATETIMEPICKER: "datetimepicker",
  FILEUPLOADER: "fileUploader",
  FILEUPLOADERSINGLE: "fileUploaderSingle",
  LIST: "list",
  LIST_RECEIPTS: "listReceipts",
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 20,
  },
}));

const localeMap = {
  en: enLocale,
  es: esLocale,
};

function FieldRenderer({
  widget,
  name,
  label,
  required,
  options,
  config,
  onChange,
  values,
}) {
  const classes = useStyles();

  function handleChange(value) {
    if (onChange) {
      onChange(value);
    }
  }

  const locale = getLanguage();

  switch (widget) {
    case WIDGET.TEXT:
      return (
        <TextField
          className={classes.formControl}
          name={name}
          label={label}
          required={required}
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
    case WIDGET.NUMBER:
      return (
        <TextField
          className={classes.formControl}
          name={name}
          label={label}
          type="number"
          required={required}
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
    case WIDGET.EMAIL:
      return (
        <TextField
          className={classes.formControl}
          name={name}
          label={label}
          type="email"
          required={required}
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
    case WIDGET.SWITCH:
      return (
        <div className={classes.formControl}>
          <Switches
            name={name}
            label={label}
            required={required}
            data={{ label: label, value: true }}
          />
        </div>
      );
    case WIDGET.SELECT:
      return (
        <Field
          name={name}
          label={label}
          required={required}
          component={Select}
          options={options}
          customOnChange={handleChange}
        />
      );
    case WIDGET.POPUP:
      return (
        <Field
          name={name}
          label={label}
          required={required}
          component={Popup}
          config={config}
          customOnChange={handleChange}
        />
      );
    case WIDGET.DATEPICKER:
      return (
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={localeMap[locale]}
        >
          <KeyboardDatePicker
            disableToolbar={false}
            name={name}
            className={classes.formControl}
            format="dd/MM/yyyy"
            label={label}
            required={required}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      );
    case WIDGET.DATETIMEPICKER:
      return (
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={localeMap[locale]}
        >
          <KeyboardDateTimePicker
            disableToolbar={false}
            name={name}
            className={classes.formControl}
            format="dd/MM/yyyy HH:mm"
            label={label}
            required={required}
            InputLabelProps={{
              shrink: true,
            }}
            ampm={false}
          />
        </MuiPickersUtilsProvider>
      );
    case WIDGET.FILEUPLOADER:
      return (
        <Field
          name={name}
          label={label}
          required={required}
          component={Uploader}
          width={"100%"}
          height={300}
        />
      );
    case WIDGET.FILEUPLOADERSINGLE:
      return (
        <Field
          name={name}
          label={label}
          required={required}
          component={UploaderSingle}
          width={"100%"}
          height={300}
        />
      );
    case WIDGET.LIST:
      return (
        <Field name={name} label={label} required={required} component={List} />
      );
    case WIDGET.LIST_RECEIPTS:
      return (
        <ReceiptsList name={name} label={label} policyId={values.PolicyId} />
      );
    default:
      return <div>{name}</div>;
  }
}

FieldRenderer.propTypes = {
  widget: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
};

export default FieldRenderer;
