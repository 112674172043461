import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../../Table";
import { useTranslation } from "react-multi-lang";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { receiptsService } from "../../../utils/feathers";
import { formatCurrency, formatDate } from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    right: 10,
    bottom: 10,
    left: "auto",
    position: "fixed",
  },
}));

function Receipts() {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    skip: 0,
    limit: 50,
    sort: { ReceiptId: -1 },
    query: {},
  });

  const changeFilters = ({ skip, query, sort }) => {
    setFilters((prev) => ({ ...prev, skip, query, sort }));
  };

  const errorMessage = t("common.error");

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await receiptsService.find({
          query: {
            $limit: filters.limit,
            $skip: filters.skip,
            $or: filters.query,
            $sort: filters.sort,
          },
        });

        setTotal(data.total);
        setRows(convertRows(data.data));
      } catch (e) {
        enqueueSnackbar(errorMessage, { variant: "error" });
      }

      setIsLoading(false);
    }

    fetchData();
  }, [filters, errorMessage, enqueueSnackbar]);

  const onDelete = async (id) => {
    await receiptsService.remove(id);
  };

  const convertRows = (rows) => {
    if (!rows) {
      return [];
    }

    return rows.map((row) => {
      return {
        ...row,
        PolicyHolderId: row.policy
          ? row.policy.policyholder.PolicyHolderId
          : null,
        CompanyId: row.policy.company ? row.policy.company.CompanyId : null,
      };
    });
  };

  const title = t("receipts.title");

  return (
    <div>
      <Helmet>
        <title>Attis - {title}</title>
      </Helmet>
      <Table
        title={title}
        path="receipts"
        idColumn="ReceiptId"
        tableColumns={[
          { title: t("receipts.id"), name: "ReceiptId" },
          {
            title: t("receipts.policyNumber"),
            name: "policy.PolicyNumber",
            getCellValue: (row) =>
              row.policy ? row.policy.PolicyNumber : undefined,
          },
          {
            title: t("receipts.companyName"),
            name: "policy.company.CompanyName",
            getCellValue: (row) =>
              row.policy.company ? row.policy.company.CompanyName : undefined,
          },
          {
            title: t("receipts.policyholderName"),
            name: "policy.policyholder.PolicyholderName",
            getCellValue: (row) =>
              row.policy.policyholder
                ? row.policy.policyholder.PolicyholderName
                : undefined,
          },
          {
            title: t("receipts.effectiveDate"),
            name: "EffectiveDate",
            getCellValue: (row) =>
              row.EffectiveDate ? formatDate(row.EffectiveDate) : undefined,
          },
          {
            title: t("receipts.expirationDate"),
            name: "ExpirationDate",
            getCellValue: (row) =>
              row.ExpirationDate ? formatDate(row.ExpirationDate) : undefined,
          },
          { title: t("receipts.receiptNumber"), name: "ReceiptNumber" },
          {
            title: t("receipts.netPremium"),
            name: "NetPremium",
            getCellValue: (row) =>
              row.NetPremium ? formatCurrency(row.NetPremium) : undefined,
          },
          {
            title: t("receipts.total"),
            name: "Total",
            getCellValue: (row) =>
              row.Total ? formatCurrency(row.Total) : undefined,
          },
        ]}
        tableRows={rows}
        totalRows={total}
        changeFilters={changeFilters}
        onDelete={onDelete}
        isLoading={isLoading}
      />
      <Fab
        color="primary"
        aria-label={t("common.new")}
        onClick={() => {
          history.push("/receipts/new");
        }}
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}

export default Receipts;
