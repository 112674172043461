import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { useTranslation } from "react-multi-lang";
import { policyholdersService } from "../../utils/feathers";
import { formatDate } from "../../utils/utils";
import Popup from "./Popup";

function List({ input }) {
  const t = useTranslation();

  const columns = [
    t("policyholders.nif"),
    t("policyholders.name"),
    t("policyholders.dateOfBirth"),
    t("policyholders.dateIdDocument"),
    t("policyholders.address"),
  ];

  const [data, setData] = useState([]);
  const [ids, setCurrentIds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (ids.length > 0) {
        const results = await policyholdersService.find({
          query: {
            $or: { PolicyHolderId: ids },
          },
        });

        setData(results.data);
      }
    }

    fetchData();
  }, [ids]);

  useEffect(() => {
    if (input.value !== ids) {
      setCurrentIds(input.value);
    }
  }, [input.value, ids]);

  function deletePolicyholder(id) {
    const newIds = ids.filter((e) => e !== id);

    if (newIds.length === 0) {
      setData([]);
    }

    setData(newIds);

    input.onChange(newIds);
  }

  function addPolicyholder(id) {
    const newIds = [...new Set([...input.value, id])];
    input.onChange(newIds);
    setCurrentIds(newIds);
  }

  return (
    <div>
      <Popup
        label={t("policyholders.searchPolicyholder")}
        config={{
          service: policyholdersService,
          label: "PolicyholderName",
          key: "PolicyHolderId",
          searchFields: ["Name", "Surname"],
        }}
        input={{
          onChange: (e) => addPolicyholder(e),
          value: null,
        }}
      />
      <TableContainer component={Paper}>
        <Table size="small" aria-label={t("policyholders.policyholdersList")}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.NIF}
                </TableCell>
                <TableCell>
                  {row.Name} {row.Surname}
                </TableCell>
                <TableCell>{formatDate(row.DateOfBirth)}</TableCell>
                <TableCell>{formatDate(row.DateIDDocument)}</TableCell>
                <TableCell>{row.Address}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => deletePolicyholder(row.PolicyHolderId)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default List;
