import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import FormGenerator from "../../FormGenerator";
import { Helmet } from "react-helmet-async";
import { documentsService, policiesService } from "../../../utils/feathers";

function EditDocument() {
  const { enqueueSnackbar } = useSnackbar();
  const t = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchData() {
      const data = await documentsService.get(id);

      setData(data);

      setIsLoading(false);
    }

    fetchData();
  }, [id]);

  async function save(values, redirect = true) {
    try {
      await documentsService.patch(id, values);

      if (redirect) {
        history.push("/documents");
      }
    } catch (e) {
      enqueueSnackbar(t("common.errorSaving"), {
        variant: "error",
      });
    }
  }

  async function validate(values) {
    let errors = {};
    if (!values.PolicyId) {
      errors.PolicyId = t("common.fieldRequired");
    }
    if (!values.DocumentName) {
      errors.DocumentName = t("common.fieldRequired");
    }
    if (!values.DocumentURL) {
      errors.DocumentURL = t("common.fieldRequired");
    }

    return errors;
  }

  return (
    <div>
      <Helmet>
        <title>Attis - {t("documents.edit")}</title>
      </Helmet>
      {!isLoading ? (
        <FormGenerator
          autoSave={true}
          fields={[
            {
              label: t("documents.basicInfoFields"),
              name: "basicInfoFields",
              children: [
                {
                  name: "PolicyId",
                  label: t("documents.policy"),
                  required: true,
                  widget: "popup",
                  config: {
                    service: policiesService,
                    label: "PolicyNumber",
                    key: "PolicyId",
                    searchFields: [
                      "policyholder.Name",
                      "policyholder.Surname",
                      "PolicyNumber",
                    ],
                  },
                },
                {
                  name: "DocumentName",
                  label: t("documents.documentName"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "DocumentURL",
                  label: t("documents.documentURL"),
                  required: true,
                  widget: "fileUploaderSingle",
                },
              ],
            },
          ]}
          values={data}
          validate={validate}
          onSubmit={save}
        />
      ) : (
        <Skeleton height={10} count={20} />
      )}
    </div>
  );
}

export default EditDocument;
