import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import FormGenerator from "../../FormGenerator";
import { Helmet } from "react-helmet-async";
import {
  bankAccountsService,
  policyholdersService,
} from "../../../utils/feathers";

function NewAccount() {
  const { enqueueSnackbar } = useSnackbar();
  const t = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchData() {
      const data = await bankAccountsService.get(id);

      setData(data);

      setIsLoading(false);
    }

    fetchData();
  }, [id]);

  async function save(values, redirect = true) {
    try {
      await bankAccountsService.patch(id, values);

      if (redirect) {
        history.push("/accounts");
      }
    } catch (e) {
      enqueueSnackbar(t("common.errorSaving"), {
        variant: "error",
      });
    }
  }

  async function validate(values) {
    let errors = {};
    if (!values.BankAccountName) {
      errors.BankAccountName = t("common.fieldRequired");
    }

    if (!values.IBAN) {
      errors.IBAN = t("common.fieldRequired");
    }

    return errors;
  }

  return (
    <div>
      <Helmet>
        <title>Attis - {t("accounts.new")}</title>
      </Helmet>
      {!isLoading ? (
        <FormGenerator
          autoSave={true}
          fields={[
            {
              label: t("accounts.basicInfoFields"),
              name: "basicInfoFields",
              children: [
                {
                  name: "PolicyHolderId",
                  label: t("accounts.policyholder"),
                  required: true,
                  widget: "popup",
                  config: {
                    service: policyholdersService,
                    label: "PolicyholderName",
                    key: "PolicyHolderId",
                    searchFields: ["Name", "Surname"],
                  },
                },
              ],
            },
            {
              label: t("accounts.accountInfoField"),
              name: "accountInfoField",
              children: [
                {
                  name: "BankAccountName",
                  label: t("accounts.accountName"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "CCC",
                  label: t("accounts.ccc"),
                  widget: "text",
                },
                {
                  name: "IBAN",
                  label: t("accounts.iban"),
                  required: true,
                  widget: "text",
                },
                {
                  name: "SEPAAuth",
                  label: t("accounts.sepaAuth"),
                  widget: "text",
                },
                {
                  name: "BIC",
                  label: t("accounts.bic"),
                  widget: "text",
                },
                {
                  name: "AuthDate",
                  label: t("accounts.authDate"),
                  widget: "datepicker",
                },
                {
                  name: "EndDate",
                  label: t("accounts.endDate"),
                  widget: "datepicker",
                },
              ],
            },
          ]}
          values={data}
          validate={validate}
          onSubmit={save}
        />
      ) : (
        <Skeleton height={10} count={20} />
      )}
    </div>
  );
}

export default NewAccount;
