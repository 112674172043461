import { format, parseISO } from "date-fns";

export function formatDate(date, outputFormat = "dd/MM/yyyy") {
  if (!date) {
    return null;
  }

  const input = parseISO(date);

  return format(input, outputFormat);
}

export function formatDbTime(date) {
  let input = date;
  if (typeof input === "string") {
    input = parseISO(input);
  }

  return format(input, "yyyy-MM-dd HH:mm");
}

export function formatCurrency(value) {
  const formatter = new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
  });

  return formatter.format(value);
}
